/*
 * @Author: liulongjie liulongjie@yuanbaobaoxian.com
 * @Date: 2022-07-15 12:44:51
 * @LastEditors: jiangwei
 * @LastEditTime: 2024-11-21 19:14:07
 * @FilePath: /fe-insurance-web/packages/fe-main-web/src/sys/order/route.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ResignRoute from './resign/route';
import UnOrderRoute from './unorder/route';
import RenewalRoute from './renewal/route';
import AliRoute from './ali/route';
import EquityRoute from './equity/route';

export default [
  {
    path: '/order',
    routes: [
      {
        path: '/order/list', // 保单列表
        component: () => import('./OrderList/OrderList'),
        title: '我家保单',
      },
      {
        path: '/order/listH', // 历史保单
        component: () => import('./OrderList/OrderListH'),
        title: '历史保单',
      },
      {
        path: '/order/detail', // 保单详情
        component: () => import('./OrderDetail'),
      },
      {
        path: '/order/unpaid', // 待支付页
        component: () => import('./Unpaid'),
      },
      {
        path: '/order/cxunpaid', // 待支付页
        component: () => import('./Unpaid'),
      },
      {
        path: '/order/middlePage', // 小金罐中间页
        component: () => import('./MiddlePage'),
      },
      {
        path: '/order/middle', // 客服中间页
        component: () => import('./Middle'),
      },
      {
        path: '/order/reduce', // 降级方案页
        component: () => import('./Reduce'),
      },
      {
        path: '/order/xbdetail', // 自动续保页
        component: () => import('./XuBaoDetail'),
      }, {
        path: '/order/policy', // 电子保单页
        component: () => import('./Policy'),
      },
      {
        path: '/order/orderDlg', // 授权页（享安、电销授权）
        component: () => import('./OrderDlg'),
      }, {
        path: '/order/correct', // 电子批单列表页
        component: () => import('./Correct'),
      }, {
        path: '/order/netGiftPolicy', // 网赠产品电子保单页
        component: () => import('./NetGiftPolicy'),
      },
      {
        path: '/order/memberInfo', // 家庭成员信息
        component: () => import('./MemberInfo'),
      },
      {
        path: '/order/editMember', // 添加家庭成员
        component: () => import('./EditMember'),
      },
      {
        path: '/order/editSuccess', // 添加家庭成员
        component: () => import('./EditSuccess'),
      },
      {
        path: '/order/prePayList', // 预扣费列表
        component: () => import('./PrePayList'),
        registerOwnShare: {
          hideShare: true,
        },
      },
      {
        path: '/order/service', // 保单服务页
        component: () => import('./ServicePage'),
        title: '保单服务',
      },
    ],
  },
  {
    path: '/record',
    routes: [{
      path: '/record/pay', // 缴费记录
      component: () => import('./PayRecord/index'),
    }],
  },
  ResignRoute, // 签约
  UnOrderRoute, // 退保
  RenewalRoute, // 续保页
  AliRoute, // 支付宝小程序保单
  EquityRoute, // 相互重疾-会员权益
];
