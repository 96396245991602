/**
 * 通用弹窗
 * 2021-05-17统一弹窗动画样式
 */
import React from 'react';
import { Modal } from 'antd-mobile-v2';
import { inject, observer } from 'mobx-react';
import { lazyLoadWithRetry } from 'ybcommon/ybutils/common/common';

const InsuranceModal = lazyLoadWithRetry(() => import('./InsuranceModal'));
const WxGifModal = lazyLoadWithRetry(() => import('./WxGifModal'));
const JKJLoginModal = lazyLoadWithRetry(() => import('./JKJLoginModal'));
const SelectModal = lazyLoadWithRetry(() => import('./SelectModal'));
const SelectModalNew = lazyLoadWithRetry(() => import('./SelectModalNew'));

const ProtocalModal = lazyLoadWithRetry(() => import('./ProtocalModal'));
/* 组合险page2abtest版本8 */
const ProtocalModalCombineV8 = lazyLoadWithRetry(() => import('./ProtocalModalCombine/V8Modal'));
const ProtocalModalComposeV10 = lazyLoadWithRetry(() => import('./ProtocalModalCompose/V10Modal'));
const ProtocalConfirmModal = lazyLoadWithRetry(() => import('./ProtocalConfirmModal'));
const CommonsModal = lazyLoadWithRetry(() => import('./CommonModal'));
const NewCommonModal = lazyLoadWithRetry(() => import('./NewCommonModal'));
const WorkPicker = lazyLoadWithRetry(() => import('./WorkPicker'));
const XbsxModal = lazyLoadWithRetry(() => import('./XbsxModal'));
const HospitalListModal = lazyLoadWithRetry(() => import('./HospitalListModal'));
const TextCommonModal = lazyLoadWithRetry(() => import('./TextCommonModal'));
const ProtectDetailModal = lazyLoadWithRetry(() => import('./ProtectDetailModal'));
const TjConfigModal = lazyLoadWithRetry(() => import('./TJConfigModal'));
const UnpaidModal = lazyLoadWithRetry(() => import('./UnpaidModal'));
const IDCardOCRModal = lazyLoadWithRetry(() => import('./IDCardOCRModal'));
const IntelligentRecommendModal = lazyLoadWithRetry(() => import('./IntelligentRecommendModal'));
const UpglideModal = lazyLoadWithRetry(() => import('./UpglideModal'));
const BackLoginModal = lazyLoadWithRetry(() => import('./BackLoginModal'));
const BatchPage2BackModal = lazyLoadWithRetry(() => import('./BatchPage2BackModal'));
const BatchPage2BackModal2 = lazyLoadWithRetry(() => import('./BatchPage2BackModal2'));
const UnfoldProtocalModal = lazyLoadWithRetry(() => import('./UnfoldProtocalModal'));
const ProtectAndPremiumWithBtnModal = lazyLoadWithRetry(() => import('./ProtectAndPremiumWithBtnModal'));
const UploadImgModal = lazyLoadWithRetry(() => import('./UploadImgModal'));
const GroupInsureIntroModal = lazyLoadWithRetry(() => import('./GroupInsureIntroModal'));
const GroupInsureProtocolModal = lazyLoadWithRetry(() => import('./GroupInsureProtocolModal'));
const CoupleLinkSelectModal = lazyLoadWithRetry(() => import('./CoupleLinkSelectModal'));
const CoupleLinkProtocalModal = lazyLoadWithRetry(() => import('./CoupleLinkProtocalModal'));
const RepeatSubmitModal = lazyLoadWithRetry(() => import('./RepeatSubmitModal'));
const StyleType6GoInsureModal = lazyLoadWithRetry(() => import('./StyleType6GoInsureModal'));
const ReintoModal = lazyLoadWithRetry(() => import('./ReintoModal'));
const PeopleModal = lazyLoadWithRetry(() => import('./PeopleModal/index'));
const fallback = () => <div />;

@inject('toolStore')
@observer
class CommonModal extends React.Component {
  renderModal = () => {
    const { toolStore } = this.props;
    const { modalVisible, modalType, modalProps, modalView, modalWrapClassName, ModalEle } = toolStore;
    switch (+modalType) {
      case 0: // 挽留弹窗
        return <InsuranceModal visible={modalVisible} {...modalProps} />;
      case 1: // 待支付提醒弹窗
        return <UnpaidModal visible={modalVisible} {...modalProps} />;
      case 8: // 调起查看关注公众号gif动画
        return (
          <WxGifModal
            visible={modalVisible}
            {...modalProps}
            onClose={() => {
              toolStore.hideModal(true);
            }}
          />
        );
      case 10: // 健康金赠险登录弹窗
        return <JKJLoginModal visible={modalVisible} {...modalProps} />;
      case 15: // 产品落地页通讯录选择弹窗
        return <SelectModal visible={modalVisible} {...modalProps} />;
      case 16: // 产品落地页通讯录选择弹窗
        return <SelectModalNew visible={modalVisible} {...modalProps} />;
      case 18: // 协议弹窗
        return <ProtocalModal visible={modalVisible} {...modalProps} />;
      case 29:
        return <WorkPicker visible={modalVisible} {...modalProps} />;
      case 33: // 续保失效，提示弹窗
        return <XbsxModal visible={modalVisible} {...modalProps} />;
      case 36: // 可报销医院弹窗
        return <HospitalListModal visible={modalVisible} {...modalProps} />;
      case 37: // 落地页可回溯版本2，协议几条款未勾选确认弹窗
        return <ProtocalConfirmModal visible={modalVisible} {...modalProps} />;
      case 49: // 保障详情弹窗
        return <ProtectDetailModal visible={modalVisible} {...modalProps} />;
      case 50: // 升级保障/费率带底部按钮弹窗
        return <ProtectAndPremiumWithBtnModal visible={modalVisible} {...modalProps} />;
      case 99:
        return <CommonsModal visible={modalVisible} {...modalProps} />;
      case 100:
        return <TextCommonModal visible={modalVisible} {...modalProps} />;
      case 101:
        return <NewCommonModal visible={modalVisible} {...modalProps} />;
      case 102: // 天机配置弹窗
        return <TjConfigModal visible={modalVisible} {...modalProps} />;
      case 103: // 直接传对应modal整个组件
        return <ModalEle visible={modalVisible} {...modalProps} />;
      case 105: // 身份证ocr弹窗
        return <IDCardOCRModal visible={modalVisible} {...modalProps} />;
      case 106: // 智能推荐弹窗
        return <IntelligentRecommendModal visible={modalVisible} {...modalProps} />;
      case 107: // 上滑手势引导弹窗
        return <UpglideModal visible={modalVisible} {...modalProps} />;
      case 108: // 批增登录页返回弹窗
        return <BackLoginModal visible={modalVisible} {...modalProps} />;
      case 109: // 批增 次页1和2，投保提示返回弹窗
        return <BatchPage2BackModal visible={modalVisible} {...modalProps} />;
      case 110: // 非折叠的二级协议弹框
        modalProps.modalTitle = modalProps.key;
        return <UnfoldProtocalModal visible={modalVisible} {...modalProps} />;
      case 111: // 组合 次页2返回弹窗
        return <BatchPage2BackModal2 visible={modalVisible} {...modalProps} />;
      case 112: // 组合 提单协议弹窗版本8
        return <ProtocalModalCombineV8 visible={modalVisible} {...modalProps} />;
      case 113: // 宠物险上传照片
        return <UploadImgModal visible={modalVisible} {...modalProps} />;
      case 114: // 拼团须知弹窗
        return <GroupInsureIntroModal visible={modalVisible} {...modalProps} />;
      case 115: // 拼团协议同意弹窗
        return <GroupInsureProtocolModal visible={modalVisible} {...modalProps} />;
      case 119: // 均分组合链路可回溯协议弹窗
        return <CoupleLinkProtocalModal visible={modalVisible} {...modalProps} />;
      case 120: // 均分组合链路选择弹窗
        return <CoupleLinkSelectModal visible={modalVisible} {...modalProps} />;
      case 121: // 二次进入弹窗
        return <ReintoModal visible={modalVisible} {...modalProps} />;
      case 123: // 重复提单确认弹窗
        return <RepeatSubmitModal visible={modalVisible} {...modalProps} />;
      case 124: // 赠险 底弹表单版 中间领取弹窗
        return <StyleType6GoInsureModal visible={modalVisible} {...modalProps} />;
      case 125: // 编辑家人资料 挽留、确认弹窗
        return <PeopleModal visible={modalVisible} {...modalProps} />;
      case 126: // 0+12组合 提单协议弹窗版本10
        return <ProtocalModalComposeV10 visible={modalVisible} {...modalProps} />;
      default:
        return (
          <Modal visible={modalVisible} transparent maskClosable={false} animated={false} wrapClassName={modalWrapClassName}>
            {modalView}
          </Modal>
        );
    }
  };

  render() {
    return <React.Suspense fallback={fallback()}>{this.renderModal()}</React.Suspense>;
  }
}

export default CommonModal;
