/**
 * Timer 状态
 */
enum Status {
  /**
   * 初始状态。
   */
  Init,
  /**
   * 当前已经触发 setTimeout，等待到达特定时间。
   */
  Pending,
  /**
   * 当前已经停止 setTimeout。
   */
  Pause,
  /**
   * 当前已经结束。
   */
  Settled,
}

/**
 * Timer 所使用的回调函数。
 *
 * @param isCancelled 是否被取消，当调用 Timer.cancel() 函数时，该参数为 true。
 */
export type TimerCallback = (isCancelled: boolean) => void;

/**
 * 基于 setTimeout 的定时器，在 setTimeout 基础上，支持暂停、恢复、中断等能力。
 */
export class Timer {
  /**
   * Timer 状态
   */
  private status = Status.Init;

  /**
   * Timer 等待时间，单位毫秒
   */
  private duration: number;

  /**
   * Timer 回调函数
   */
  private callback: TimerCallback;

  /**
   * 内部触发的 setTimeout 返回的句柄。
   */
  private handler: number | null = null;

  /**
   * 触发 start() / resumed() 的时间戳。
   */
  private timestamp: number | null = null;

  /**
   * 创建 Timer
   *
   * @param duration 定时器时长，即过多少时间会触发回调函数 callback，传入小于 0 的数字将被视为 0。
   * @param callback 等待触发的回调函数。
   */
  constructor(duration: number, callback: TimerCallback = () => {}) {
    this.setDuration(duration);
    this.callback = callback;
  }

  /**
   * 启动 Timer。
   */
  start() {
    if (this.status === Status.Settled || this.status === Status.Pending) {
      return;
    }
    if (this.status === Status.Pause) {
      // 走 resumed 流程
      this.resumed();
      return;
    }

    this.status = Status.Pending;
    this.timestamp = Date.now();
    this.handler = window.setTimeout(() => {
      this.onFinished(false);
    }, this.duration);
  }

  /**
   * 暂停 Timer，停止计时，更新剩余时间。
   */
  pause() {
    if (this.status !== Status.Pending) {
      return;
    }

    this.status = Status.Pause;
    if (this.handler === null) {
      return;
    }

    this.clear();
    this.setDuration(this.duration - (Date.now() - this.timestamp));
  }

  /**
   * 恢复 Timer 计时。
   */
  resumed() {
    if (this.status !== Status.Pause) {
      return;
    }

    this.status = Status.Pending;
    this.timestamp = Date.now();
    this.handler = window.setTimeout(() => {
      this.onFinished(false);
    }, this.duration);
  }

  /**
   * 重置 Timer 数据，方便复用实例。
   *
   * @param duration 定时器时长，即过多少时间会触发回调函数 callback
   * @param callback 等待触发的回调函数
   */
  reset(duration: number, callback: TimerCallback = () => {}) {
    this.status = Status.Init;
    this.setDuration(duration);
    this.callback = callback;
    this.clear();
  }

  /**
   * 停止计时（如果正在计时），立刻同步触发回调函数（回调函数中标记 isCancelled = false）。
   */
  settle() {
    this.onFinished(false);
    this.clear();
  }

  /**
   * 停止计时（如果正在计时），立刻同步触发回调函数（回调函数中标记 isCancelled = true）。
   */
  cancel() {
    this.onFinished(true);
    this.clear();
  }

  /**
   * 获取当前 Timer 的状态
   * @returns 当前 Timer 状态
   */
  getStatus() {
    return this.status;
  }

  /**
   * 获取当前 Timer 剩余等待时间
   * @returns 剩余等待时间，单位毫秒
   */
  getDuration() {
    return this.duration;
  }

  /**
   * 当前 Timer 状态 是否为 {@link Status.Init}
   */
  isInit() {
    return this.getStatus() === Status.Init;
  }

  /**
   * 当前 Timer 状态 是否为 {@link Status.Pending}
   */
  isPending() {
    return this.getStatus() === Status.Pending;
  }

  /**
   * 当前 Timer 状态 是否为 {@link Status.Pause}
   */
  isPaused() {
    return this.getStatus() === Status.Pause;
  }

  /**
   * 当前 Timer 状态 是否为 {@link Status.Settled}
   */
  isSettled() {
    return this.getStatus() === Status.Settled;
  }

  /**
   * 修改剩余时间。
   * @param duration 单位毫秒
   */
  changeRemainDuration(duration: number) {
    switch (this.getStatus()) {
      case Status.Init:
      case Status.Pause:
        this.setDuration(duration);
        break;
      case Status.Pending:
        this.pause();
        this.setDuration(duration);
        this.resumed();
        break;
      default:
        // Settle 状态不处理
        break;
    }
  }

  private setDuration(newDuration: number) {
    this.duration = Math.max(newDuration, 0);
  }

  private onFinished(isCancelled: boolean) {
    // 已经 settle，不需要再处理
    if (this.status === Status.Settled) {
      return;
    }

    this.callback(isCancelled);
    this.status = Status.Settled;
  }

  /**
   * 清理 timer
   */
  private clear() {
    window.clearTimeout(this.handler);
    this.handler = null;
  }
}

/**
 * 等待一定的时间。使用 {@link Timer} 实现。
 *
 * @param duration 等待时间
 * @param timer 传入 timer 并复用该 Timer 实例，否则将新建一个 Timer 实例。
 * @return 返回用于等待的 Promise 和 Timer 实例。
 */
export function wait(duration: number, timer?: Timer) {
  let resolveFn: (TimerCallback) | null = null;

  const promise = new Promise<boolean>((resolve) => {
    resolveFn = resolve;
  });

  if (!timer) {
    timer = new Timer(duration, resolveFn);
  } else {
    timer.reset(duration, resolveFn);
  }
  timer.start();

  return {
    timer,
    promise,
  };
}
