/**
 * 产品详情页落地页，必须传入有效insuranceId，默认是19版百万
 */

import React from 'react';
import stores from 'store';
import ybModal from 'fe-yb-modal';
import { openHrefUrl } from 'store/UrlTools';
import { Toast } from '@yb/fe-component';
import { judgeProduct, getInsuranceId, isLongProduct, isShortProduct } from 'utils/common/common';
import { transferSearchObj2Str, getLocationSearch } from 'ybcommon/ybutils/common/common';
import { getHost, isXiangAn } from 'ybcommon/ybutils/common/company';
import { trackEvent } from 'ybcommon/ybutils/statistic';
import AudioBGManager from 'src/common/audioBGManager';
import { modalTaskRegister, modalTaskDelete } from 'src/businessTool';
import Fetch from 'fetch';

export default class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    const { location } = props;
    this.params = getLocationSearch(location?.search); // 获取参数
    const productAbb = this.params.productAbb; // 产品简称
    this.insuranceId = this.params.insuranceId || getInsuranceId(productAbb); // 产品id
    this.insuranceVersion = isShortProduct(this.insuranceId) ? 2 : 1; // 1--批增1.0产品  2--批增2.0产品
    this.state = {
      result: null,
    };
  }

  componentDidMount() {
    this._registerModalCB();
    this._initPage();
  }

  componentWillUnmount() {
    this._deleteModalCB();
  }

  _initPage = () => {
    const _p = window.__pathname;
    let _insuranceId;
    const { coreData } = stores.userStore;
    const { productAbbrMap = {} } = coreData;
    const { tkbw19 } = productAbbrMap;
    const { history } = this.props;
    const { protocol, search, pathname } = window.location;

    if (isLongProduct(this.insuranceId) && pathname !== '/cargo/cxDetail') {
      // 长险，重定向路由
      history.replace(`/cargo/cxDetail${search}`);
      return;
    }
    // 享安链接强跳经纪链接 享安的出单方式现已下架
    if (isXiangAn() && _p === '/cargo/detail' && !__DEV__) {
      const { cnhost } = getHost();
      const redirectUrl = `${protocol}//${cnhost}/cargo/detail${search}`;
      openHrefUrl(redirectUrl, true);
      return;
    }
    if (_p === '/cargo/seodetail' || _p === '/cargo/bwdetail') {
      _insuranceId = tkbw19;
      this._updateUrl(_insuranceId);
    } else {
      this._getConfig();
    }
  };

  // 注册弹窗打开关闭之前的相关回调
  _registerModalCB = () => {
    modalTaskRegister?.('blockAudio', [(props) => {
      if (props?.audioBlocking) {
        // console.log('beforeOpen....');
        AudioBGManager.getInstance().blockBGAudio('backModal');
      }
    }, (props) => {
      if (props?.audioBlocking) {
        // console.log('afterClose....');
        AudioBGManager.getInstance().closeBGAudioBlock('backModal');
      }
    }]);
    modalTaskRegister?.('closeYBModal', [(props) => {
      // console.log('beforeOpen....');
      // 默认关闭元速弹窗，若不想关闭，则需传入notCloseYbModal:true
      const { userStore } = stores;
      if (!props?.notCloseYbModal && ybModal.hasModal() && !userStore.hasShowHalfScreen) ybModal.closeAll?.();
    }, null]);
  };

  // 删除相关注册的弹窗回调
  _deleteModalCB = () => {
    modalTaskDelete?.('blockAudio');
    modalTaskDelete?.('closeYBModal');
  };

  _changeUrl = (_url) => {
    setTimeout(() => {
      openHrefUrl(_url, true);
    }, 0);
  };

  /**
   * 更新路径
   * @param {*} _insuranceId 产品id
   * @param {*} isSH 是否是审核版
   */
  _updateUrl = (_insuranceId, isSH = false) => {
    const { search } = window.location;
    const _p = window.__pathname;
    let newHost = '/cargo/detail';
    if (_p.indexOf('gift') !== -1) {
      newHost = '/gift/detail';
    }
    if (search) {
      this._changeUrl(`${newHost}${search}&insuranceId=${_insuranceId}&isSH=${isSH ? 1 : 0}`);
    } else {
      this._changeUrl(`${newHost}?insuranceId=${_insuranceId}&isSH=${isSH ? 1 : 0}`);
    }
  };

  _oldRedirectNew = (abbr) => {
    const { history } = this.props;
    this.params.insuranceId = getInsuranceId(abbr);
    const newSearch = transferSearchObj2Str(this.params);
    history.replace(`${window.location.pathname}${newSearch}`);
  };

  _getConfig = async () => {
    try {
      let productType = 1;
      const data = await Fetch.post('api/h5/insurance/beforeLandingPage/query', { insuranceId: this.insuranceId });
      const { landingType = 1 } = data || {};
      let addr;
      if (+landingType === 2) { // 分流配置化落地页批增产品
        if (this.insuranceVersion === 2) {
          const { history } = this.props;
          // 批增2.0产品
          history.replace(`/cargo/login${window.location.search}`);
          return;
        }
        productType = 3;
        // 新版本-配置化的批增产品
        addr = () => import('../batchnew/Base');
        this._renderPage(addr);
      } else if (+landingType === 3 || this.params.isCmsView === '2') {
        // 保险2.0配置产品 isCmsView 是为了未上架的产品可以正常预览
        if (+this.params.pz === 2) {
          // 批增2.0 已经不在使用了
          productType = 4;
          addr = () => import('../insurance/batch');
        } else {
          productType = 6;
          addr = () => import('../insurance/cargo');
        }
        this._renderPage(addr);
      } else { // 产品1.0落地页
        this._getOldConfig();
      }
      trackEvent('h5_all_pzdetail', { type: productType });
    } catch (err) {
      Toast.fail(err);
    }
  };

  _getOldConfig = () => {
    let addr;
    if (judgeProduct(this.insuranceId, 'tkbw20')) {
      // 20版百万
      addr = () => import('../api/BWTDetail');
    } else if (judgeProduct(this.insuranceId, 'htjjyw')) {
      // 华泰
      addr = () => import('../api/HTDetail');
    } else if (judgeProduct(this.insuranceId, 'CHILD')) {
      // 华泰少儿意外
      addr = () => import('../api/CHILD');
    } else if (judgeProduct(this.insuranceId, 'TKMZ')) {
      // 少儿门诊
      addr = () => import('../api/TKMZ');
    } else if (judgeProduct(this.insuranceId, 'ygqlb')) {
      // 齐鲁保
      addr = () => import('../api/QLB');
    } else if (judgeProduct(this.insuranceId, 'FDSMKYRS')) {
      // 富德重疾险
      // 富德重疾险 旧的产品跳转到对应的新产品
      this._oldRedirectNew('FDSMYBZJ');
      return;
    } else if (judgeProduct(this.insuranceId, 'bdaxblq')) {
      // 北大方正安行
      // 北大方正安行 旧的产品跳转到对应的新产
      this._oldRedirectNew('bdaxbbkzhywbxcpjh');
      return;
    } else if (judgeProduct(this.insuranceId, 'bdzxshlq')) {
      // 北大方正尊享
      // 北大方正尊享 旧的产品跳转到对应的新产品
      this._oldRedirectNew('bdzxssckbxcpjh');
      return;
    } else if (judgeProduct(this.insuranceId, 'ymyw_jcb')) {
      // 疫苗意外险-基础版
      addr = () => import('../api/YMYW');
    } else if (judgeProduct(this.insuranceId, 'tkymx_qjb')) {
      // 疫苗意外险-旗舰版
      addr = () => import('../api/YMYWQJ');
    } else if (judgeProduct(this.insuranceId, 'tkyqjt_ff')) {
      // 泰康疫情津贴险
      addr = () => import('../api/YQJT');
    } else if (isLongProduct(this.insuranceId) || +this.params.isCmsView === 1) {
      // 长险落地页 | cms预览
      addr = () => import('../ChangXian/Land');
    } else if (
      // 泰康新样式--以中华链路2为基础
      judgeProduct(this.insuranceId, 'jqrzxbwylx') // 电销
    ) {
      // 批增基础百万落地页--泰康
      addr = () => import('../batch/TKNew');
    } else if (
      judgeProduct(this.insuranceId, 'paazylxgzgjqb') // 平安钩子(企保渠道:电销) 平安防癌批增高价百万
      || judgeProduct(this.insuranceId, 'paazylxgzgjyb') // 平安钩子(永保渠道:电销) 平安防癌批增高价百万
    ) {
      // 批增基础百万落地页--平安
      addr = () => import('../batch/PA');
    } else {
      // 默认版详情页
      addr = () => import('../api/NewDetail');
    }

    this._renderPage(addr);
  };

  _renderPage = (addr) => {
    const { history, location } = this.props;

    addr()
      .then((component) => {
        const app = () => <component.default history={history} location={location} />;
        this.setState({
          result: app(),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { result } = this.state;
    return result;
  }
}
