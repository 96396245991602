/**
 * 身份证号模拟input
 */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { AnimateUtil, device } from 'fe-yb-tools';
import { abTestHitReport } from 'ybcommon/ybutils/common/shuntutil';
import { trackEvent } from 'ybcommon/ybutils/statistic';
import { InteractionTopic, PageInteractionLogManager } from 'ybcommon/ybutils/PageInteractionLogManager';
import CustomKeyboard from './CustomKeyboard';
import styles from './index.less';

const { isIOS } = device;
const keysDelay = 200; // 键盘显示延迟时间ms（目的是等原始键盘落下后再展示自定义键盘）

// 放大镜元素id
const ScaleInputEleId = 'scale-input-content';

class IDCard extends Component {
  // 与antd-moble inputItem 组件参数含义一致
  static defaultProps = {
    isFormatIdCard: true, // 是否格式化身份证号
    handClassName: '', // 小手的类名
    errorShowType: 0, // 异常展示类型：1-原版（仅仅标红）2-异常展示优化版（border包裹，异常信息提示在input下方）;
    errorText: '', // 异常信息文本 errorShowType===2的时候需要使用
    id: 'yb-idcard-input', // 输入框的id
    placeholder: '', // placeholder文案
    value: null, // 值，传进来的value需要去掉空格
    onChange: () => { }, // change 回调
    onHandClick: () => { }, // 点击小手回调
    onFocus: () => { }, // focus 回调
    onBlur: () => { }, // blur 回调
    extra: null, // 右边额外渲染
    onExtraClick: () => { }, // extra点击回调
    error: false, // 是否报错
    maxLength: 18, // 最大长度，数字
    className: null, // 类名
    handImg: '', // 小手图片
    errorCertNoHasChange: false, // 错误状态身份证框是否修改
    isPreventFocus: false, // 是否阻止聚焦
    onPreventFocusEmit: () => { },
    showScale: false, // 是否显示放大镜效果
    onErrorTipClick: () => { }, // 点击错误提示那一行的回调方法
    inputHandStyle: {}, // 外面传入的小手的Style样式
    showNoErrorFocusingStyle: false, // 命中link3FormAbtest为版本4/6时，当无报错聚焦时，要调整整体样式为：白底加灰色placeholder
    /** 是否隐藏清空按钮 */
    isHideClearBtn: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      focus: false, // 模拟input是否聚焦
      inputingIndex: 0, // 当前正在输入的位置
      runShowScaleAni: false, // 运行显示放大镜的动画
    };
    this.hasHitReport = false; // 是否上报过实验命中
  }

  componentDidMount() {
    // input框 阻止键盘的长摁事件
    const idCardContainer = document.getElementsByClassName('yb-idcard-container');
    if (idCardContainer) {
      this.idCardArr = Array.from(idCardContainer) || [];
      this.idCardArr.map((item) => item.addEventListener('contextmenu', (e) => { e.preventDefault(); }));
    }
  }

  componentWillUnmount() {
    // input框 取消监听
    if (this.idCardArr) { this.idCardArr.map((item) => item.removeEventListener('contextmenu', (e) => { e.preventDefault(); })); }
    // 键盘部分 取消监听
    if (this.container) this.container.removeEventListener('contextmenu', (e) => { e.preventDefault(); });
    this._removeBlurListener();
  }

  _addBlurListener = () => {
    document.addEventListener('touchstart', this.doBlur, false);
  };

  _removeBlurListener = () => {
    document.removeEventListener('touchstart', this.doBlur, false);
  };

  // 模拟input失焦
  doBlur = (ev) => {
    const { value } = this.props;
    let el = ev.target;
    while (el && el.parentElement) {
      if (el === this.iptExtra || el.parentElement === this.iptExtra) {
        break;
      }
      if (el.parentElement === this.iptBoxRef || el.parentElement === this.iptScaleBoxRef || el === this.iptScaleBoxRef) {
        return;
      }
      if (el && el.parentElement) {
        el = el.parentElement;
      }
    }
    this._onBlur(value);
  };

  /**
   *  隐藏键盘
   */
  unLinkInput = () => {
    if (this.customKeyboard) {
      this.customKeyboard.setDisplay(false);
    }
    this._removeBlurListener();
  };

  // change 回调
  _onChange = (val) => {
    const { onChange } = this.props;
    if (val?.length < 1) this.setState({ runShowScaleAni: false });
    if (onChange) onChange(val);
  };

  // 组件外部主动触发focus
  uiFocus = () => {
    this._removeBlurListener();
    this._addBlurListener();
    this._setFocusState();
  };

  // 模拟input的原始方法
  focus = () => {
    this._onFocus();
  };

  // focus 回调
  _onFocus = (val) => {
    const { onFocus, isPreventFocus, onPreventFocusEmit, value, idCardAbtest } = this.props;
    val = val || value;
    if (isPreventFocus) {
      // 阻止聚焦事件
      onPreventFocusEmit();
      return;
    }

    // 上报实验命中
    if (idCardAbtest?.requestId && !this.hasHitReport) {
      this.hasHitReport = true;
      abTestHitReport([{ configCode: 'idCardAbtest', requestId: idCardAbtest.requestId }]);
    }
    trackEvent('all_selfmadekeyboard_click');
    const { focus } = this.state;
    if (focus) return;
    this._removeBlurListener();
    this._addBlurListener();
    if (onFocus) {
      onFocus(val, () => {
        this._setFocusState();
      });
    }
  };

  _setFocusState = () => {
    const { value } = this.props;
    this.setState(
      {
        focus: true,
        inputingIndex: value.length,
      },
      () => {
        /** 当前正在进行聚焦，记录交互 */
        PageInteractionLogManager.startInteraction(InteractionTopic.FocusInput);
        trackEvent('all_selfmadekeyboard_show');
        this._textScrollToView();
        if (this.customKeyboard) {
          setTimeout(() => {
            const { focus: _focus } = this.state;
            if (_focus) {
              this.customKeyboard?.setDisplay(true);
            }
          }, keysDelay);
        }
      },
    );
  };

  // 将身份证号滚动到可视区域
  _textScrollToView = () => {
    if (!this.iptBoxRef) return;
    setTimeout(() => {
      if (!this.iptBoxRef) return;
      const container = document.scrollingElement;
      // 窗口高度
      const windowHeight = isIOS() ? (document.documentElement.clientHeight || document.body.clientHeight) : window.originHeight;
      // 身份证输入框底部距离窗口的底部距离
      const bottomPx = windowHeight - this.iptBoxRef.getBoundingClientRect().bottom;
      const inputHeight = this.iptBoxRef.getBoundingClientRect().height;
      // 输入框底部距屏幕上方的距离小于输入框的高度，代表输入框现在在上方被遮挡了
      if (bottomPx < inputHeight) {
        const height = inputHeight + 100 - bottomPx;
        const topPos = container && container.scrollTop - height;
        if (container && topPos) {
          AnimateUtil.start(container, 'scrollTop', topPos, 800, 'strongEaseOut');
        }
      } else {
        // 如果距离小于指定值 则滚动
        const minBottomPx = 300;
        if (bottomPx < minBottomPx) {
          const topPos = container && container.scrollTop + (minBottomPx - bottomPx);
          if (container && topPos) {
            AnimateUtil.start(container, 'scrollTop', topPos, 800, 'strongEaseOut');
          }
        }
      }
    }, keysDelay);
  };

  // blur 回调
  _onBlur = (val) => {
    const { onBlur } = this.props;
    const { focus } = this.state;
    if (!focus) return;
    if (onBlur) onBlur(val);
    this.uiBlur();
    /** 结束聚焦交互 */
    PageInteractionLogManager.endCurInteraction();
  };

  // 组件外主动调用blur
  uiBlur = () => {
    this.setState({
      focus: false,
      inputingIndex: 0,
      runShowScaleAni: false,
    });
    this.unLinkInput();
  };

  // 键盘点击
  _onKeyboardClick = (keyboardVal) => {
    const { maxLength, onStatic, onBeyondMaxLength } = this.props;
    const { value } = this.props;
    const { inputingIndex: oldIndex } = this.state;
    let valueAfterChange;
    if (keyboardVal === 'delete') {
      if (value.length === 0 || oldIndex === 0) return;
      valueAfterChange = value.substring(0, oldIndex - 1) + value.substring(oldIndex, value.length);
      this.setState({
        inputingIndex: oldIndex - 1,
      });
      if (onStatic) onStatic();
      this._onChange(valueAfterChange);
    } else if (keyboardVal === 'hide') {
      this._onBlur(value);
    } else {
      if (maxLength && +maxLength >= 0 && (value + keyboardVal).length > maxLength) {
        if (onBeyondMaxLength) onBeyondMaxLength();
        return;
      }
      if (oldIndex !== value.length) {
        valueAfterChange = value.substring(0, oldIndex) + keyboardVal + value.substring(oldIndex, value.length);
      } else {
        valueAfterChange = value + keyboardVal;
      }
      this.setState({
        inputingIndex: oldIndex + 1,
      });
      this._onChange(valueAfterChange);
    }
  };

  /**
   * 清除按钮点击
   */
  _clearBtnAction = (e) => {
    e.stopPropagation();
    this._onChange('');
    this.setState({
      inputingIndex: 0,
    });
  };

  /**
   *  移动光标到指定位置
   * @param {*} e 经过计算的位置
   * @returns
   */
  _moveInputingIndex = (e) => {
    const { inputingIndex: curIndex } = this.state;
    if (e !== curIndex) {
      this.setState({
        inputingIndex: e,
      });
    }
  };

  /**
   *  判断事件是否落在input具体value值上，没有的判断位置在前还是后
   * @param {*} 事件event对象
   * @returns 具体element或者null
   */
  _getValueElementIndex = (e, inputContentDomId) => {
    const { touches = [] } = e || {};
    const { id, value } = this.props;
    const inputEle = document.getElementById(inputContentDomId || id);
    const { inputingIndex: curIndex } = this.state;
    const touch = touches[0];
    // 没有点击位置或者没有数字的时候直接返回当前位置
    if (!touch || inputEle.childNodes.length === 0) {
      return curIndex;
    }
    for (let i = 0; i < inputEle.childNodes.length - 1; i++) {
      const ele = inputEle.childNodes[i];
      const rect = ele.getBoundingClientRect();
      // 先判断数字是否在左边，直接定位到0位
      if (i === 0 && touch.clientX < rect.x) {
        return 0;
      }
      if (this._inItemRect(rect, touch)) {
        if (i === 0) { // 测试反馈0位不好点，加半个数字的范围（加完以后测试和交互觉得可以）
          return touch.clientX < (rect.x + rect.width / 2) ? 0 : 1;
        }
        return this._getNumElementId(ele);
      }
      // 最后没有命中任何数字的时候定位到最后
      if (i === inputEle.childNodes.length - 2) {
        return value.length;
      }
    }
    return curIndex;
  };

  /**
   * 获取对应的id
   * @param {HTML.NODE} e
   * @returns id
   */
  _getNumElementId = (e) => {
    if (e && e.innerText === ' ') {
      const nextEl = e.nextSibling;
      return +nextEl.id + 1;
    }
    return +e.id + 1;
  };

  /**
   *  点击位置是否在对应节点上
   * @param {*} ele  节点
   * @param {*} touch 点击位置
   * @returns bool
   */
  _inItemRect = (rect, touch) => {
    if ((touch.clientX >= rect.x && touch.clientX <= rect.x + rect.width)
      && touch.clientY >= rect.y - 6 && touch.clientY <= rect.y + rect.height + 6
    ) {
      return true;
    }
    return false;
  };

  _touchStartHandler = (e, inputContentDomId) => {
    const { onClickScaleCallback } = this.props;
    if (typeof onClickScaleCallback === 'function' && inputContentDomId === 'scale-input-content') onClickScaleCallback();
    const ele = this._getValueElementIndex(e, inputContentDomId);
    this._moveInputingIndex(ele);
  };

  // 自定义键盘渲染
  _renderCustomKeyboard = () => {
    const { idCardAbtest = {} } = this.props;
    return (
      ReactDOM.createPortal(<CustomKeyboard ref={(ref) => (this.customKeyboard = ref)} onKeyboardClick={this._onKeyboardClick} idCardAbtest={idCardAbtest} />, this._getContainer())
    );
  };

  // 获取自定义键盘的container 并阻止键盘的长摁事件
  _getContainer = () => {
    // 键盘部分 阻止键盘的长摁事件
    let container = document.querySelector('#yb-keyboard-container');
    if (!container) {
      container = document.createElement('div');
      container.setAttribute('id', 'yb-keyboard-container');
      document.body.appendChild(container);
    }
    // 此时container必然存在 不加判断
    container.addEventListener('contextmenu', (e) => { e.preventDefault(); });

    // 解决部分iOS手机自定义键盘因为webview移动造成的定位不准问题
    if (isIOS()) {
      container.setAttribute('style', 'position: fixed; z-index: 1000;');
    }

    this.container = container;

    return this.container;
  };

  // 渲染label
  _renderLabel = () => {
    const { children } = this.props;
    return <div className="yb-input-label">{children}</div>;
  };

  // 渲染数字以及光标
  _renderValueItem = (value, isFormatIdCard) => {
    const { focus, inputingIndex } = this.state;
    const { id } = this.props;
    return (
      <React.Fragment>
        {
          value.split('').map((item, index) => {
            const key = `${id}_${index}`;
            if (isFormatIdCard && ((index === 6 && value.length > 6) || (index === 14 && value.length > 14))) {
              return (
                <React.Fragment key={key}>
                  <span key={`${key}_space`} className="space"> </span>
                  <span
                    key={key}
                    id={index}
                    className={`${focus && (inputingIndex - 1 === index) ? 'focus' : ''}`}
                  >{item}
                  </span>
                </React.Fragment>
              );
            }
            return (
              <span
                key={key}
                id={index}
                className={`${focus && (inputingIndex - 1 === index) ? 'focus' : ''}`}
              >{item}
              </span>
            );
          })
        }
      </React.Fragment>
    );
  };

  // inpnt模拟
  _renderInput = (inputContentDomId) => {
    const { placeholder, error, id } = this.props;
    const { focus, inputingIndex } = this.state;
    const { value, isFormatIdCard } = this.props;
    return (
      <div
        className="yb-input-control"
      >
        <div className={`yb-input-container ${error ? 'error' : ''}`}>
          {!value && <div className="yb-input-placeholder">{placeholder}</div>}
          <div
            id={inputContentDomId || id}
            className={`yb-input-content ${focus && inputingIndex === 0 ? 'focus' : ''}`}
          >
            {
              // 放大镜里面必定格式化，不受props控制，输入框里面的手props.isFormatIdCard控制
              this._renderValueItem(value, inputContentDomId === ScaleInputEleId || isFormatIdCard)
            }
            {focus && (<div className="touchContainer" onTouchStart={(e) => this._touchStartHandler(e, inputContentDomId)} />)}
          </div>
        </div>
      </div>
    );
  };

  // 渲染extra
  _renderExtra = () => {
    const { extra, onExtraClick, value, isHideClearBtn } = this.props;
    const { focus } = this.state;
    if (!isHideClearBtn && focus && value.length > 0) {
      return this._renderClearBtn();
    }
    if (!extra) return null;
    return (
      <div
        className="yb-input-extra"
        ref={(ref) => (this.iptExtra = ref)}
        onClick={(e) => {
          e.stopPropagation();
          if (onExtraClick) onExtraClick(e);
        }}
      >
        {extra}
      </div>
    );
  };

  /**
   * 聚焦时展示一键清除按钮
   */
  _renderClearBtn = () => (
    <img
      className="yb-input-clear"
      alt=""
      src={require('./img/icon_clear.png')}
      onClick={this._clearBtnAction}
    />
  );

  /**
   * @method 渲染放大镜效果
   */
  _renderScaleContent = () => {
    const { runShowScaleAni } = this.state;
    if (!runShowScaleAni) {
      setTimeout(() => { // 显示过渡动画
        this.setState({ runShowScaleAni: true });
      }, 100);
    }
    return (
      <div className={classnames('yb-idcard-container', 'yb-idcard-scale', { 'scale-ani': runShowScaleAni })} ref={(ref) => (this.iptScaleBoxRef = ref)}>
        {this._renderInput(ScaleInputEleId)}
        <div className="scale-arrow"> </div>
      </div>
    );
  };

  _renderInputContent = () => {
    const { className, value, inputContainerClassName, errorShowType, showScale } = this.props;
    const { focus } = this.state;
    return (
      <>
        <div
          className={classnames('yb-idcard-container', className, { [inputContainerClassName]: errorShowType !== 1 })}
          ref={(ref) => (this.iptBoxRef = ref)}
          onClick={() => this._onFocus(value)}
        >
          <div className="yb-list-line">
            {this._renderLabel()}
            {this._renderInput()}
            {this._renderExtra()}
          </div>
        </div>
        {this._renderCustomKeyboard()}
        {showScale && value?.length >= 1 && focus && this._renderScaleContent()}
      </>
    );
  };

  /**
   * 异常突出显示的input部分内容
   * @returns
   */
  _renderErrObviousInputContent = () => {
    const { showHand, highlight, inputContainerClassName, error, errorText, errorCertNoHasChange, onErrorTipClick, showNoErrorFocusingStyle } = this.props;
    const { focus } = this.state;
    let inputBoxClass = '';
    if (errorCertNoHasChange) {
      inputBoxClass = 'yb-input-cardNo-inputLightBorderBlackText';
    } else if (error) {
      inputBoxClass = 'yb-input-cardNo-inputErrBox';
    } else if (showNoErrorFocusingStyle && focus) { // 当无报错聚焦时，调整样式为：白底加灰色placeholder
      inputBoxClass = 'yb-input-cardNo-noErrorFocusingStyle';
    } else if (showHand || focus || highlight) {
      inputBoxClass = 'yb-input-cardNo-guideHighlight';
    }

    // console.log('log inputBoxClass===', inputBoxClass);

    return (
      <div className={classnames(inputContainerClassName, styles.inputBox, inputBoxClass)}>
        {this._renderInputContent()}
        <div className={classnames('errorTips', styles.errorTips, { [styles.none]: !(error && errorText) })} onClick={onErrorTipClick}>
          <img className={styles.errorIcon} src={require('./img/errorIcon.png')} alt="" />
          <span>{errorText}</span>
        </div>
      </div>
    );
  };

  render() {
    const { showHand, handImg, errorShowType, onHandClick, handClassName, inputHandStyle } = this.props;

    return (
      <div style={{ position: 'relative' }} id="yb-input-cardNo-wrap">
        {showHand && <img alt="" className={classnames(styles.hand, handClassName)} id="idCard-hand" style={inputHandStyle} src={handImg || require('./img/hand.png')} onClick={onHandClick} />}
        {
          errorShowType === 1
            ? this._renderErrObviousInputContent()
            : this._renderInputContent()
        }
      </div>
    );
  }
}

export default IDCard;
