/*
 * @Author: cuilinchao
 * @Date: 2024-11-28 10:12:52
 * @LastEditors: cuilinchao
 * @LastEditTime: 2024-11-28 15:07:34
 * @Description: 短信投诉页-路由
 */
export default {
  path: '/smsComplaint',
  routes: [
    {
      path: '/smsComplaint/detail',
      component: () => import('./Detail'),
      title: '用户保护中心',
    },
    {
      path: '/smsComplaint/submit',
      component: () => import('./Submit'),
      title: '用户保护中心',
    },
    {
      path: '/smsComplaint/result',
      component: () => import('./Result'),
      title: '用户保护中心',
    },
  ],
};
