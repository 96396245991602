/*
 * @Author: shixiaoxia
 * @Date: 2022-10-24 14:23:14
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-11-01 19:43:52
 * @Description: 公司类处理方法
 */
import stores from 'store';
import { device } from 'fe-yb-tools';
import { getEnv } from 'ybcommon/envUtil';

const { isWechat, isWeMini, isJingjiApp, isShouxinApp } = device;

const timeNow = Date.now();

// 是否首信域名
export const isShouxin = (host) => {
  // if (__DEV__) return true;
  const _host = host || window.location.host;
  const _result = _host.indexOf('shouxinbaoxian.com') !== -1;
  return _result;
};

// 判断当前域名是否是元保经纪域名
export const isCN = (host) => {
  const _host = host || window.location.host;
  const _result = _host.indexOf('yuanbaobaoxian.cn') !== -1;
  return _result;
};

// 判断当前域名是否在世捷开元域下
// export const isShiJiKaiYuan = () => {
//   const _host = window.location.host;
//   const _result = _host === 'devexchange.shijiekaiyuan.com' || _host === 'exchange.shijiekaiyuan.com' || _host === 'testexchange.shijiekaiyuan.com' || _host === 'exchange.hljjytkj.com';
//   return _result;
// };

// 判断当前域名是否在享安域名下
export const isXiangAn = () => {
  const _host = window.location.host;
  const _result = _host === 'exchange.xa-online.com' || _host.indexOf('yuanbaobaoxian.com') !== -1;
  return _result;
};

// 是否数科域名
export const isYB = (host) => {
  const _host = host || window.location.host;
  const _result = _host.indexOf('ybaobx.com') !== -1;
  return _result;
};

// 是否白鸽保域名
export const isBaiGeHost = () => {
  const _host = window.location.host;
  const _result = _host.indexOf('baigebao') !== -1;
  return _result;
};

// 是否科创域名
export const isKc = (host) => {
  const _host = host || window.location.host;
  const _result = _host.indexOf('yuanbaokc.com') !== -1;
  return _result;
};

/**
 * 获取公众号名称
 * @returns 公众号名称
 */
export const getGZHName = () => {
  let gzhName = '元保健康';
  if (isCN()) {
    gzhName = '元保';
  } else if (isShouxin()) {
    gzhName = '元保优选';
  }
  return gzhName;
};

/**
 * 获取tab数据
 * @returns tabData tab数据
 */
export const getTabData = () => {
  let tabData = [{
    title: '首页',
    link: '/',
    icon: 'iconshouye1',
    iconselect: 'iconshouye-xuanzhong1',
    active: true,
  },
  {
    title: '产品',
    link: '/cargo/index',
    icon: 'iconchanpin1',
    iconselect: 'iconchanpin-xuanzhong',
    active: true,
  },
  {
    title: '攻略',
    link: '/schoolroom/detail',
    icon: 'iconbaoxiangonglveh5',
    iconselect: 'iconbaoxiangonglveh5_02',
    active: false,
  },
  {
    title: '我的',
    link: '/my',
    icon: 'iconwode',
    iconselect: 'iconwode-xuanzhong1',
    active: false,
  }];
  if (isShouxin()) {
    tabData = [{
      title: '首页',
      link: '/',
      icon: 'iconicon_home_line',
      iconselect: 'iconicon_home',
      active: true,
    }, {
      title: '产品',
      link: '/cargo/index',
      icon: 'iconicon_cb_unchoose_line',
      iconselect: 'iconicon_cb_unchoose',
      active: false,
    }, {
      title: '我的',
      link: '/my',
      icon: 'iconicon_my_line',
      iconselect: 'iconicon_my',
      active: false,
    }];
  }
  return tabData;
};

/**
 * 获取对应环境的cn和yabobx域名
 * TODO
 */
export const getHost = () => {
  const env = getEnv();
  let cnhost = 'exchange.yuanbaobaoxian.cn';
  let ybaobxhost = 'exchange.ybaobx.com';
  let sxhost = 'exchange.shouxinbaoxian.com';
  switch (env) {
    case 'pre':
      cnhost = 'exchange-pre.yuanbaobaoxian.cn';
      ybaobxhost = 'exchange-pre.ybaobx.com';
      break;
    case 'online':
      break;
    default:
      cnhost = `${env}${cnhost}`;
      ybaobxhost = `${env}${ybaobxhost}`;
      sxhost = `${env}${sxhost}`;
      break;
  }
  return {
    cnhost,
    ybaobxhost,
    sxhost,
  };
};

/**
 * 获取当前环境的appCode 用户中心接口使用
 * @returns appCode 跟环境、域名相关
 */
export const getAppCode = (isWeChatMiniApp) => {
  let appCode = isShouxin() ? 'h5_app_sx' : 'h5_app_web'; // h5

  if (isWechat() && !isWeMini()) {
    if (isCN()) {
      appCode = 'wx_gzh_jingji';
    } else if (isShouxin()) {
      appCode = 'wx_gzh_sx';
    } else {
      appCode = 'wx_gzh_yb';
    }
  } else if (+isWeChatMiniApp === 1) {
    appCode = 'wx_miniapp_mall'; // 主站小程序
  } else if (+isWeChatMiniApp === 5) {
    appCode = 'wx_miniapp_sx';
  } else if (+isWeChatMiniApp === 6) { // 数科支付宝
    appCode = 'zfb_miniapp_skts';
  } else if (+isWeChatMiniApp === 7) { // 数科支付宝-退款助手
    appCode = 'zfb_miniapp_tkzs';
  } if (+isWeChatMiniApp === 8) { // 数科支付宝-退款售后
    appCode = 'zfb_miniapp_tksh';
  } else if (+isWeChatMiniApp === 9) { // 抖音经纪小程序
    appCode = 'dy_miniapp_mall';
  } else if (+isWeChatMiniApp === 10) { // 抖音首信小程序
    appCode = 'dy_miniapp_ybyx';
  }
  return appCode;
};

/**
 * 获取主体名称
 * entity = {
 isJingJi: bool,
 isShouXin: bool,
 }
 * @returns 主体名称
 */
export const getCompanyName = (entity) => {
  if (entity) {
    if (entity.isJingJi) {
      return '元保';
    }
    if (entity.isShouXin) {
      return '元保优选';
    }
  }
  let gzhName = '元保';
  if (isShouxin()) {
    gzhName = '元保优选';
  }
  return gzhName;
};

/**
 * 获取咨询电话
 * @returns 咨询电话
 */
export const getTelphone = () => {
  let phone = '4006050808';
  if (isCN()) {
    phone = '4006050860';
  } else if (isShouxin()) {
    phone = '4006160170';
  }
  return phone;
};

/**
 *  获取title
 * @param {*} [isSpecial] 是否是特殊页面默认展示（该字段已弃用，产品已统一）
 * @returns 页面默认标题
 */
export const getTitle = (isSpecial) => {
  let title = isSpecial ? '元保，保险不难选' : '元保，持牌保险专家';
  if (window.location.href.indexOf('/sxcx/result') > -1) {
    // 亿学、橡树保结果页
    title = '投保结果页';
  } else if (window.location.href.indexOf('/sxcx') > -1 || window.location.href.indexOf('/cargo/coopdetail') > -1) {
    // 亿学、橡树保落地页
    title = '产品详情页';
  } else if (window.location.href.indexOf('/pay/transition') > -1 && window.location.href.indexOf('noStandardPayType=1') > -1) {
    // 第三方api下单产品过渡页（亿学蓝医保）
    title = '支付';
  } else if (window.location.href.indexOf('/order/unpaid') > -1 && window.location.href.indexOf('noStandardPayType=1') > -1) {
    // 第三方api下单产品待支付页（亿学蓝医保）
    title = '待支付页';
  } else if (isBaiGeHost()) {
    title = '保你美好生活';
  } else if (isShouxin()) {
    title = isSpecial ? '元保优选，保障健康生活' : '元保优选，持牌保险专家';
  } else if (window.location.href.indexOf('=220809155229898BI60TEST') > -1 || window.location.href.indexOf('=220809162646829BI67TEST') > -1
    || window.location.href.indexOf('=230529153412310BI792736') > -1 || window.location.href.indexOf('=230529153528779BI127521') > -1) {
    title = '百万医疗险2023';
  }
  return title;
};

/**
 * 获取登录协议
 * @params changeKey 是否需要修改protocol的字段名为key/value 1-需要
 * @params userEntity 用户登录主体
 * @returns protocols 登录协议
 */
export const getLoginProtocols = (changeKey, userEntity) => {
  const { protocolPage } = stores.userStore || {};
  if (!protocolPage) {
    return [];
  }

  /* userEntity */
  /* 1 经济 2 首信 3 橡树保  */

  let protocols;
  if (userEntity) {
    // 传入用户主体
    protocols = userEntity === 1 ? protocolPage.LoginCN : protocolPage.LoginYBBX;
    if (userEntity === 2) {
      protocols = protocolPage.LoginSX;
    }
  } else {
    protocols = isCN() ? protocolPage.LoginCN : protocolPage.LoginYBBX;
    if (isShouxin()) {
      protocols = protocolPage.LoginSX;
    }
  }

  if (isJingjiApp() || isShouxinApp()) {
    // app 内打开不展示保单协议
    protocols = protocols.filter((item) => item.title !== '保单管理服务协议');
  }

  if (changeKey) {
    return protocols && protocols.map((item) => ({ key: item.title, value: item.protocolId }));
  }
  return protocols;
};

/**
 * 获取通用协议
 * @params changeKey 是否需要修改protocol的字段名为key/value 1-需要
 * @params {{isJingJi: boolean; isShouXin: boolean;}} entityInfo
 * @returns protocols 获取通用协议
 */
export const getProtocols = (changeKey, entityInfo) => {
  const { protocolPage } = stores.userStore || {};
  if (!protocolPage) {
    return [];
  }
  let protocols = (isCN() || __DEV__) ? protocolPage.CN : protocolPage.YBBX;
  if (isShouxin()) {
    protocols = protocolPage.SX;
  }
  // 如果传递了产品主体则优先以产品主体为主取值
  if (entityInfo) {
    if (entityInfo.isJingJi) {
      protocols = protocolPage.CN;
    } else if (entityInfo.isShouXin) {
      protocols = protocolPage.SX;
    }
  }

  if (changeKey) {
    return protocols && protocols.map((item) => ({ key: item.title, value: item.protocolId }));
  }
  return protocols;
};

// 根据域名判断主体编码
export const getBusinessOrigin = () => {
  let companyCode = '';
  if (isXiangAn()) {
    companyCode = 'xa_jingji';
  } else if (isYB()) {
    companyCode = 'yb_shuke';
  } else if (isShouxin()) {
    companyCode = 'sx_daili';
  } else {
    companyCode = 'yb_jingji';
  }
  return companyCode;
};

// 公司配置
export const companyConfig = {
  // 经纪公司简称
  jingJiCompanyName: '元保经纪',
  // 经纪公司全程
  jingJiCompanyFullName: '元保保险经纪（北京）有限公司',
  // 首信公司简称
  shouXinCompanyName: '首信代理',
  // 首信公司全称
  shouXinCompanyFullName: '首信保险代理（广东）有限公司',
  // 首信logo url
  shouXinLogoUrl: 'https://cdn.yuanbaobaoxian.com/ybassets/shouXinLogo.png',
  // 非首信logo url
  notShouXinLogoUrl: 'https://cdn.yuanbaobaoxian.com/ybassets/YBLogo.png',
  // 代理后续文字
  suffixAgent: '提供中介服务',
};

/**
   * 获取客户告知书及对应提示文案
   * notification：首信 notification2：元保 notification3：白鸽保 notification5: 利宝
   * isComplianceName 如果是合规名称要求, 则走合规名称逻辑
   * @returns noti-客户告知书协议Id txt-提示文案
   */
export const getNotification = (isComplianceName = false) => {
  const { notification = {}, notification2 = {}, notification3 = {} } = stores.userStore || {};
  let noti;
  let agent;

  if (isCN()) {
    noti = notification2;
    agent = isComplianceName ? companyConfig.jingJiCompanyFullName : companyConfig.jingJiCompanyName;
  } else {
    noti = notification;
    agent = isComplianceName ? companyConfig.shouXinCompanyFullName : companyConfig.shouXinCompanyName;
  }
  let txt = `${agent}${companyConfig.suffixAgent}`;

  const selfLogo = isShouxin() ? `${companyConfig.shouXinLogoUrl}?t=${timeNow}` : companyConfig.notShouXinLogoUrl;
  // 白鸽保域名
  if (isBaiGeHost()) {
    noti = notification3;
    txt = '由白鸽宝保险经纪有限公司提供保险中介服务';
    agent = '白鸽宝保险经纪有限公司';
  }
  return { noti, txt, selfLogo, agent };
};
