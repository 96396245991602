/*
 * @Author: shixiaoxia
 * @Date: 2024-09-21 10:19:16
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-09-21 16:45:39
 * @Description: 获取加密userid主体信息
 */
import { AxiosResponse } from 'axios';

import { CommonHeader, DataResponse } from '../../base';
import { CommonApiParams } from '../../../types';
import { bffFetch } from '../bffFetch';
import { UserGroupReq } from './UserGroupReq';
import { UserGroupResult } from '../types';

/**
 * 查询用户是否领取到该产品
 * @param params 特有参数
 * @param commonApiParams 公共参数
 * @param commonHeader 统一header参数
 * @returns 产品领取结果
 */
export async function getUserGroup(
  params: UserGroupReq,
  commonApiParams: CommonApiParams,
  commonHeader: CommonHeader,
): Promise<AxiosResponse<DataResponse<UserGroupResult>>> {
  const result = await bffFetch(commonHeader).post('api/mall/bff/user-group', { ...commonApiParams, ...params });
  return result;
}
