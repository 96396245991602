export default class MaskDomUtil {
  private static timer = null;

  private static maskDom = null;

  // 添加遮罩 不可操作页面
  static addMaskDom(time = 3) {
    // 已经存在的情况下不再增加
    if (!MaskDomUtil.maskDom) {
      MaskDomUtil.maskDom = document.createElement('div');
      MaskDomUtil.maskDom.style.cssText = 'width: 100%; height: 100%; background: transparent; position: fixed; z-index: 9999; top: 0; bottom: 0; left: 0; right: 0;';
      // ios兼容
      MaskDomUtil.maskDom.style.touchAction = 'none';
      MaskDomUtil.maskDom?.addEventListener('touchmove', MaskDomUtil.handleMaskDomTouchMoveEvent);

      document.body?.appendChild(MaskDomUtil.maskDom);
    }

    if (typeof time === 'number') {
      if (MaskDomUtil.timer) {
        clearTimeout(MaskDomUtil.timer);
        MaskDomUtil.timer = null;
      }
      // 默认 3 s后自动释放
      MaskDomUtil.timer = setTimeout(() => {
        MaskDomUtil.removeMaskDom();
      }, time * 1000);
    }
  }

  // 添加遮罩 不可操作页面，可以设置超时时间，没有默认的超时时间
  static addMaskDomWithoutDefaultRemoveTime(timeout?: number, zIndex = 9999) {
    // 已经存在的情况下不再增加
    if (!MaskDomUtil.maskDom) {
      MaskDomUtil.maskDom = document.createElement('div');
      MaskDomUtil.maskDom.style.cssText = `width: 100%; height: 100%; background: transparent; position: fixed; z-index: ${zIndex}; top: 0; bottom: 0; left: 0; right: 0;`;
      // ios兼容
      MaskDomUtil.maskDom.style.touchAction = 'none';
      MaskDomUtil.maskDom?.addEventListener('touchmove', MaskDomUtil.handleMaskDomTouchMoveEvent);

      document.body?.appendChild(MaskDomUtil.maskDom);
    }

    if (typeof timeout === 'number') {
      if (MaskDomUtil.timer) {
        clearTimeout(MaskDomUtil.timer);
        MaskDomUtil.timer = null;
      }
      MaskDomUtil.timer = setTimeout(() => {
        MaskDomUtil.removeMaskDom();
      }, timeout * 1000);
    }
  }

  // 移除遮罩
  static removeMaskDom() {
    if (MaskDomUtil.maskDom) {
      document.body?.removeChild(MaskDomUtil.maskDom);
      MaskDomUtil.maskDom?.removeEventListener('touchmove', MaskDomUtil.handleMaskDomTouchMoveEvent);
      MaskDomUtil.maskDom = null;
    }

    if (MaskDomUtil.timer) {
      clearTimeout(MaskDomUtil.timer);
      MaskDomUtil.timer = null;
    }
  }

  // 遮罩禁止默认事件
  private static handleMaskDomTouchMoveEvent = (event) => {
    event.preventDefault();
  };
}
