/* eslint-disable no-useless-escape */
import { autorun, action, observable, computed, makeObservable } from 'mobx';
import ybModal from 'fe-yb-modal';
import { setCanUseLocalBackListener, autoAddHistory, autoBackHistory } from 'ybcommon/ybutils/common/backutil';
import { getSource } from 'ybcommon/ybutils/common/common';
import { addNewUserInfoToUrl } from 'utils/common/common';
import { isWechat, isWeMini, isJingjiApp, isShouxinApp } from 'utils/device';
import userStore from 'src/store/UserStore';
import { InteractionTopic, PageInteractionLogManager, PageInteractionLogManager2 } from 'ybcommon/ybutils/PageInteractionLogManager';

export class ToolStore {
  cache = {
    PayFromURL: '_ZY_BEFORE_H5_PAY_PAGE_URL_',
    PayFromOrder: '_ZY_BEFORE_H5_PAY_ORDER_ID_',
  };

  optimizeModalProps = {};

  /**
   * 是否禁用ToolStore里的返回监听
   */
  isDisableToolStoreBackFun = false;

  constructor() {
    makeObservable(this);
    // 做防抖处理
    autorun(
      () => {
        if (this.hasShowModal) {
          this.convertMouseMove('forbidden');
        } else {
          this.convertMouseMove('allow');
        }
      },
      { delay: 20 },
    );
  }

  @observable showKF = false; // 控制是否显示客服悬浮按钮

  /** ****************************************
   * 以下控制loading模态窗口
   **************************************** */

  @observable showYBLoading = false; // 元保定制动画loading

  @observable isLoading = false;

  @observable needShowLogin = false; // 是否在当前页面显示登录弹窗

  @action showLoading() {
    this.isLoading = true;
  }

  @action hideLoading() {
    this.isLoading = false;
  }

  /** ****************************************
   * 以下拦截登录显示登录弹窗工具类
   **************************************** */
  successCallback = null;

  failCallback = null;

  closeCallBack = null;

  bodyStyle = '';

  loginModalVersion = { // 登录弹窗ab测
    version: 1, // 1-橘色旧版 2-橘色放大版
    autoLogin: true, // 是否自动发送验证码&登录
    isJiyan: false, // 是否极验登录
    acceptProtocolVersion: 1, // 0 原版(默认展示验证码输入框) 1 协议弹窗先出 2 验证码先出
  };

  /** ****************************************
   * 以下拦截容器为弹窗打开之前、关闭之后回调处理
   **************************************** */
  modalShowInterceptor = new Map();

  @action doLogin(callback, closeCallBack, failCallback) {
    // 2-支付宝 3-支付宝小程序 4-微信小程序 或者 页面路径为 /lucky' 、 '/wheelDisc
    if (isJingjiApp() || isShouxinApp()) {
      this.updateLoginModalVersion({
        isJiyan: false,
        version: 1,
        acceptProtocolVersion: 0,
        autoLogin: false,
        canUseFaceID: true, // 是否可以使用人脸识别
      });
    } else if (
      [2, 3, 4].includes(getSource())
      || ['/lucky', '/wheelDisc'].includes(window.__pathname || window.location.pathname)
    ) {
      this.updateLoginModalVersion({ version: 1 });
    } else {
      this.updateLoginModalVersion({ version: 1, isJiyan: true, acceptProtocolVersion: 2 });
    }
    const bodyStyle = document.querySelector('body').style.cssText;
    if (!/^overflow:\s?hidden;?$/i.test(bodyStyle)) this.bodyStyle = bodyStyle;
    this.convertMouseMove('forbidden');
    this.successCallback = callback;
    this.closeCallBack = closeCallBack;
    this.failCallback = failCallback;
  }

  // 手机号登陆成功后调用
  @action loginSuccess(isRegiste) {
    this.needShowLogin = false;
    this.convertMouseMove('allow');
    document.body.setAttribute('style', this.bodyStyle);

    if (this.successCallback) {
      // 由于获取订阅的模板状态请求放置到了各个页面，如果成功登录后且传了回调函数的，需要重新获取
      if (isWechat() && !isWeMini()) {
        userStore.getSubscribeTemplates().then(() => {
          this.successCallback(isRegiste);
        });
      } else {
        this.successCallback(isRegiste);
      }
    } else if (isWeMini()) {
      // 小程序内刷新，需要附带最新的用户信息
      const link = addNewUserInfoToUrl(window.location.href);
      window.location.replace(link);
    } else {
      window.location.reload();
    }
  }

  @action hiddenLoginModal() {
    this.needShowLogin = false;
    this.convertMouseMove('allow');
    document.body.setAttribute('style', this.bodyStyle);

    if (this.closeCallBack) {
      this.closeCallBack();
    }
  }

  @action updateLoginModalVersion(data) {
    this.loginModalVersion = { ...this.loginModalVersion, ...(data || {}) };
    this.needShowLogin = true;
  }

  /** ****************************************
   * 以下处理微信支付工具类
   **************************************** */
  @action showPayModal(msg, onClose) {
    // 主要用于赠险处有百万下单失败处使用，这个目前搭售已不再使用
    this.showModalByType(100, {
      isSingleBtn: true,
      isBottomBtn: true,
      contentText: msg?.content,
      confirmText: '知道了',
      onConfirm: () => {
        this.hideModal(false);
        if (onClose) {
          onClose(msg);
        }
      },
    }, false);
  }

  /** ****************************************
   * 以下通用弹窗
   **************************************** */
  @observable modalVisible = false;

  @observable optimizeModalVisible = false;

  @observable modalType = -1;

  @observable modalProps = null;

  @observable modalView = null;

  @observable modalWrapClassName = null;

  @observable mouseStatus = true; // 是否可以响应滑动，跟随convertMouseMove方法变化

  // modal组件，可以直接传入整个弹窗组件，需要将modalEle当做一个属性传入，会在使用后删除改属性
  // toolStore.showModalByType(103, { modalEle: Modal, ...props })，props为该组件需要的属性
  ModalEle = null;

  ybModalIds = observable.map({});

  @action addYbModalId(id) {
    if (id) {
      this._handleBeforeOpen(this.modalProps);
      // 将modalProps暂存，removeYbModalId时使用（点击返回按钮等弹窗被动关闭的情况，removeYbModalId时modalProps已被清空）
      this.newModalProps = { ...(this.modalProps || {}) };
      this.ybModalIds.set(id, 1);
    }
  }

  @action removeYbModalId(id) {
    if (id && this.ybModalIds.has(id)) {
      this.ybModalIds.delete(id);
      this._handleAfterClose(this.newModalProps);
      // 关闭弹窗后将newModalProps清空
      this.newModalProps = null;
    }
  }

  @computed get hasShowModal() {
    return this.modalVisible || this.ybModalIds.size > 0;
  }

  @action convertMouseMove(flag) {
    if (this.notNeedRecordScrollTop) { // 是否 不需要记录scrollTop 设置为true在打开弹窗时 不再触发convertMouseMove中的position设置
      return;
    }
    try {
      if (flag === 'forbidden' && document.body.style.position !== 'fixed') {
        this._oldScrollTop = document.scrollingElement.scrollTop || this._oldScrollTop || 0;
        document.body.setAttribute('style', `position: fixed;top: -${this._oldScrollTop}px;overflow: hidden;`);
        this.mouseStatus = false;
      } else if (flag === 'allow' && !ybModal.hasModal()) {
        document.body.setAttribute('style', 'position: static;');
        document.scrollingElement.scrollTop = this._oldScrollTop;
        this._oldScrollTop = 0;
        setTimeout(() => {
          this.mouseStatus = true;
        }, 200);
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * 关闭弹窗之后的相关处理
   * @param {*} type 弹窗类型
   */
  _handleAfterClose = (props) => {
    // console.log('before...', type, props);
    if (this.modalShowInterceptor.size) {
      for (let callbacks of this.modalShowInterceptor.values()) {
        if (callbacks.constructor !== Array) callbacks = [callbacks];
        const afterClose = callbacks[1];
        afterClose?.(props);
      }
    }
  };

  /**
   * 打开弹窗之前的相关处理
   * @param {*} type 弹窗类型
   * @param {*} props 弹窗相关props
   */
  _handleBeforeOpen = (props) => {
    // console.log('before...', type, props);
    if (this.modalShowInterceptor.size) {
      for (let callbacks of this.modalShowInterceptor.values()) {
        if (callbacks.constructor !== Array) callbacks = [callbacks];
        const beforeOpen = callbacks[0];
        beforeOpen?.(props);
      }
    }
  };

  interactionId = null;

  @action showModalByType(type, props, needAutoPushPath = true, notNeedRecordScrollTop = false) {
    const useYBModalArr = [100, 101, 102]; // 使用YBModal的弹窗，统一将useYBModal设为true
    if (props && useYBModalArr.indexOf(type) > -1) props.useYBModal = true;
    if (type === 103) { // props必须传入modalEle，即弹窗组件
      this.ModalEle = props.modalEle;
      delete props.modalEle;
    }
    if (needAutoPushPath) {
      autoAddHistory();
    }
    this.notNeedRecordScrollTop = notNeedRecordScrollTop; // 是否 不需要记录scrollTop 设置为true在打开弹窗时 不再触发convertMouseMove中的position设置
    this.modalVisible = true;
    this.modalType = type;
    this.modalProps = props;
    /** 打开弹窗 记录弹窗交互 */
    PageInteractionLogManager.startInteraction(InteractionTopic.ShowModal);
    this.interactionId = PageInteractionLogManager2.startInteraction(InteractionTopic.ShowModal);
  }

  @action showModal(detailView, wrapClassName, needAutoPushPath = true) {
    if (needAutoPushPath) {
      autoAddHistory();
    }
    this.modalVisible = true;
    this.modalView = detailView;
    this.modalWrapClassName = wrapClassName;
    /** 打开弹窗 记录弹窗交互 */
    PageInteractionLogManager.startInteraction(InteractionTopic.ShowModal);
    this.interactionId = PageInteractionLogManager2.startInteraction(InteractionTopic.ShowModal);
  }

  @action hideModal(isGoBack = true, useYBModal = false) {
    this.modalVisible = false;
    /** 关闭弹窗 记录弹窗交互 */
    PageInteractionLogManager.endCurInteraction(InteractionTopic.ShowModal);
    PageInteractionLogManager2.endInteraction(this.interactionId);
    this.modalView = null;
    this.modalWrapClassName = null;
    if (isGoBack) {
      autoBackHistory();
    }

    if (!useYBModal) {
      this.modalType = -1;
      this.modalProps = null;
      this.ModalEle = null;
    }
  }

  optimizeModalInteractionId = null;

  @action showOptimizeModal(modalProps) {
    autoAddHistory(); // 升级弹窗展示时，push一次state
    this.optimizeModalVisible = true;
    this.optimizeModalProps = modalProps;
    /** 打开弹窗 记录弹窗交互 */
    PageInteractionLogManager.startInteraction(InteractionTopic.ShowModal);
    this.optimizeModalInteractionId = PageInteractionLogManager2.startInteraction(InteractionTopic.ShowModal);
  }

  @action hideOptimizeModal() {
    const dom = document.getElementById('htmlC');
    if (dom) dom.scrollTop = 0;
    this.optimizeModalVisible = false;
    /** 关闭弹窗 记录弹窗交互 */
    PageInteractionLogManager.endCurInteraction(InteractionTopic.ShowModal);
    PageInteractionLogManager2.endInteraction(this.optimizeModalInteractionId);
    // TODO: review 讨论不置空的影响
    // this.optimizeModalProps = {};
  }

  /** ****************************************
   * 以下悬浮活动
   **************************************** */

  // 自定义身份证键盘是否展示
  @observable customKeyBoardIsShow = false;

  @action changeCustomKeyBoardShow(isShow) {
    this.customKeyBoardIsShow = isShow;
  }

  /** 绑定正在展示的自制键盘 */
  customKeyboard = null;

  /**
   *
   * @returns 监听popstate 事件的处理函数
   */
  popstateListener = () => {
    // console.log('e===', e);
    if (this.isDisableToolStoreBackFun) return;
    if (this.customKeyBoardIsShow && this.customKeyboard) { // 自定义键盘是否未收起
      this.customKeyboard?.props?.onKeyboardClick('hide'); // 只关闭自定义键盘，输入框还在编辑态，需要直接调用输入框blur的方法
    }
    if (this.optimizeModalVisible) { // 无需关闭store modal
      return;
    }
    if (this.modalVisible) {
      const useYBModal = this.modalType === 101; // modalType为101的时候使用的是YBModal
      this.hideModal(false, useYBModal);
      setCanUseLocalBackListener(false);
    }
  };
}

const _store = new ToolStore();
export default _store;
