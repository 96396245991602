export interface CopuleJudgeResult {
  orderId: string; // 订单号
  orderIdEncrypt: string; // 加密订单号
  coupleId: string; // 组合Id
  coupleFlag: number; // 可组合标识
  coupleInsuranceId: string; // 组合B产品id
  userIdencrypt: string; // 加密用户id
  insuranceId: string; // 产品id
}

// eslint-disable-next-line no-shadow
// export enum InsuranceType {
//   SHORT_AND_GIFT_VERSION1 = 1, // 短险 赠险1.0
//   SHORT_AND_GIFT_VERSION2 = 2, // 短险 赠险2.0
//   LONG_INSURANCE = 3, // 长险 赠险1.0
//   ZERO_PLUS_LONGL = 4, // 0 + 长险
//   ONE_PLUSE_TWELEVE = 5, // 1 + 12
// }

export interface QueryDetailReq {
  insuranceId?: string;
  landingPageVersion?: string;
  queryType?: number; // // 预览时传1 可查询待上架产品信息
}

export enum Entity {
  JingJi = 1,
  ShouXin = 2,
  XiangShuBao = 3,
}

// 商城接口ybComponentId的值和Entity的映射
export const ENTITY_AND_PRODUCTID_MAP = {
  yuanbao: Entity.JingJi,
  beiJinBranch: Entity.JingJi,
  shuke: Entity.JingJi,
  shouxin: Entity.ShouXin,
};

/**
 * 短险 赠险2.0 产品详情
 */
export interface MallQueryDetailResult {
  insuranceId: string; // 产品id;
  productAddedValueVo: {
    detailsPageBanner: string; // 详情页banner;
    headerImg: string; // 增值服务头图;
    addedValueList: Array<{
      desc: string; // 增值服务描述;
      icon: string; // 增值服务示意图标;
      jumpLinkUrl: string; // 跳转链接;
      name: string; // 增值服务名称;
      popupImg: string; // 弹窗图 2 弹窗时必填;
      type: number; // 类型 1 链接 2 弹窗;
      time: number; // 增值服务次数 小于等于0就是不限制次数;
    }>;
  };
  baseInfo: {
    applicantAgeRuleVo: {
      calculateBase: number; // 投保人年龄计算基准 1-投保日 2-保单生效日，默认 投保日;
      endType: number; // 最大年龄类型 1-天 2-周岁 3-不限制;
      endValue: number; // 最大年龄;
      startType: number; // 最小年龄类型 1-天 2-周岁;
      startValue: number; // 最小年龄;
    };
    applicantInsurantRelations: Array<string>; // 投被保人关系类型 (多选)，1-本人 2-父母 3-子女 4-配偶 5-雇佣 6-抚养 7-赡养 8-其他，默认本人;
    businessRuleVo: {
      firstBusinessType: number; // 一级业务类型;
      secondBusinessType: number; // 二级业务类型;
      thirdBusinessType: number; // 三级业务类型;
    };
    companyId: number; // 保险公司枚举id;
    companyName: string; // 保险公司名称;
    /** 保险公司全称 */
    companyFullName: string;
    createBy: string; // 创建人;
    crossPayRuleVo: {
      crossPayDay: number; // 跨天支付天;
    };
    effectiveRuleVo: {
      effectiveDay: number; // 保单生效时间;
    };
    firstInsuranceType: string; // 新保险类型 一级 1-赠险 2-付费险;
    graceRuleVo: {
      graceDay: number; // 缴费宽限期;
      includePayDay: number; // 缴费宽限期是否包含缴费日 1-是 2-否;
      effectDateRule: number; // 当月无生效日时缴费日 1 - 当月最后一天（默认选项） 2 - 次月第一天;
    };
    hesitationRuleVo: {
      hesitationDay: number; // 退保犹豫期;
    };
    insuranceId: string; // 产品id;
    insuranceInfo: string; // 产品说明;
    insuranceType: string; // 保险类型 1-医疗、2-意外、3-重疾、4-人寿、5-年金、6-财产、7-赠险、8-投连;
    insurantAgeRuleVo: {
      calculateBase: number; // 被保人年龄计算基准 1-投保日 2-保单生效日，默认 保单生效日;
      endType: number; // 最大年龄类型 1-天 2-周岁 3-不限制;
      endValue: number; // 最大年龄;
      startType: number; // 最小年龄类型 1-天 2-周岁;
      startValue: number; // 最小年龄;
    };
    insurePeriod: number; // 保障天数;
    insurePeriodType: number; // 保障期限类型 1-一年以下 2-一年期 3-长期;
    isBirthday: number; // 生日当天是否满周岁 1-是 2-否 默认是，即生日当天算满周岁;
    isBw: number; // 是否百万保险;
    isCrossPay: number; // 是否支持跨天支付;
    isSocialInsurance: number; // 是否限制有社保;
    jobRuleVo: {
      endJobValue: number; // 结束值;
      startJobValue: number; // 开始值;
    };
    productAbb: string; // 产品简称;
    productId: string; // 保司产品id 多个用英文逗号隔开;
    productName: string; // 保司产品名称;
    productUsage: number; // 产品用途 1-线上使用 2-销售使用 默认：线上使用;
    saleName: string; // 产品销售名称;
    secondInsuranceType: string; // 新保险类型 二级 1-医疗、2-意外、3-重疾、4-人寿、5-年金、6-财产、8-投连;
    status: number; // 产品状态 1-草稿中、2-待上架、3-已上架、4-约定上架中、5-已下架、6-约定下架中;
    thirdInsuranceType: string; // 新保险类型 三级 待确定;
    uneffectiveRuleVo: {
      uneffectiveDay: number; // 未缴费保单失效时间 1 - 缴费日00:00:00；2- 宽限期最后一天23:59:59；3- 宽限期最后一天次日00:00:00;
    };
    updateBy: string; // 修改人;
    limitType: number; // 保险期限 1-短期险 2-长期险;
    channel: number; // 产品渠道;
    connectType: number; // 对接方式 1-API对接 2-H5对接;
    productUrl: string; // H5产品链接;
    connectionParams: string; // 对接参数;
    routingRuleVo: {
      requireRouting: number; // 是否需要路由 0 否 1 是（isBw=0不填，isBw=1 必填）;
      routingRule: number; // 路由规则 0 路由来 1 路由走 （requireRouting=0不填，requireRouting=1 必填）;
      destination: string; // 路由目标 目标保险id;（routingRule=0 不填，routingRule=1 必填）;
    };
    correctionDocument: string; // 批改升级协议;
    companyRule: {
      companyLogo: string; // 保司logo;
    };
    discountType: number; // 折扣类型 0-非折扣 1-拼团折扣;
    discountRate: undefined; // 折扣系数;
    isReagent: number; // 是否转代理 0-否  1-是;
    certTypes: Array<number>; // 证件类型 1:居民身份证； 21:外国人永久居留身份证;
    ybCompanyId: keyof typeof ENTITY_AND_PRODUCTID_MAP; // 产品出单主体
  };
  outOrderDomain: Array<{
    id: number; // 顺序;
    code: string; // 保司编码;
    company: string; // 出单主体;
    domain: string; // 域名;
    disabled: boolean; // 前段定义;
  }>;
  productPageMaterialVo: {
    initiativePayDoc: string; // 主动支付文案;
    pageInsuranceDuty: number; // 页面保障责任默认收起 1-展示 2-收起;
    payNextPremiumBtn: number; // 是否支持支付全年保费 1-是 2-否;
    payYearPremiumBtn: number; // 是否支持支付全年保费 1-是 2-否;
    productPlanList: Array<{
      planId: string; // 方案Id;
      planName: string; // 方案名称;
      productPlanDutyList: Array<{
        0: {
          desc: string; // 条款解读;
          dutyId: string; // 责任id;
          dutyName: string; // 责任名称;
          insuredAmount: string; // 保额;
          isTotalInsuredAmount: number; // 是否计入总保额;
        };
      }>;
      contractsNew: Array<{
        key: string; // 保险协议标题;
        value: string; // 保险协议id;
      }>;
      contractsYBaoBx: Array<{
        key: string; // 保险协议标题;
        value: string;
      }>;
      contractsShouXin: Array<{
        key: string; // 保险协议标题;
        value: string;
      }>;
      contractsCn: Array<{
        key: string;
        value: string;
      }>;
      contractsTc: Array<{
        key: string;
        value: string;
      }>;
      contractsTcOut: Array<{
        key: string;
        value: string;
      }>;
      contractsDsCn: Array<{
        key: string;
        value: string;
      }>;
      productPlanId: string; // 保司方案id;
      compensateJson: Array<{
        key: string; // 标题;
        value: string; // 内容;
      }>;
      processJson: Array<{
        key: string;
        value: string;
      }>;
      qaJson: Array<{
        key: string;
        value: string;
      }>;
      qaLandingPage: Array<{
        key: string;
        value: string;
      }>;
      monthMinPremium: undefined; // 月保费最小费率;
      yearMinPremium: undefined; // 年保费最小费率;
      deductibles: string; // 免赔额;
      compensationRatio: string; // 赔付比例;
      discountMonthMinPremium: undefined; // 月保费折扣最小费率;
      discountYearMinPremium: undefined; // 年保费折扣最小费率;
      /** icp相关数据 */
      termsInfoList: Array<{
        termsTitle: string;
        registerNo: string;
      }>;
    }>;
    pagePremiumMethod: number; // 页面费率配置方式 1-文字 2-上传图片;
    premiumMonthPay: string; // 页面费率表信息（月缴）;
    premiumYearPay: string; // 页面费率表信息（年缴）;
    premiumInfoImg: string; // 页面费率表信息图;
    premiumDoc: string; // 完整费率文案;
    isPremiumUpload: number; // 是否费率表上传 1-不上传 2-为上传;
    unpaidEntrance: string; // 待支付入口 格式：{"WECHAT":1,"WECHAT_MINI_APP":1,"KUAISHOU_MINI_APP":1,"DOUYIN_MINI_APP":1}，其中0为未勾选，1为勾选;
    premiumHead: Array<{
      head: string; // 表头字段名称;
      child: any;
    }>;
    premiumMonth: Array<any>;
    premiumYear: Array<{
      isArrivalCountry: number; // 是否展示旅行目的地 1-是，2-否;
    }>;
  };
  payInfo: {
    payCompanyRuleVo: {
      paySignTemplate: string; // 支付签约模板;
      productMerchantId: string; // 保司商户号;
    };
    payMethods: Array<string>; // 支付方式 (复选) 1:微信，2:支付宝，3:银行卡，99:其他;
    paySignType: number; // 签约方式 1-支付中签约，2-纯签约，支付方式选择微信和支付宝时，签约方式必填，默认 支付中签约;
    payTemplateRuleVoList: Array<{
      0: {
        templateId: string; // 模板;
        templateRatio: undefined; // 比例;
      };
    }>;
    payType: number; // 收费方式 1-元保代收 2-保司收费 3-保司托管账户收费，默认 元保代收;
    refundType: number; // 退费方式 1-元保代退 2-保司退费 默认 元保代退;
    delayPayRuleVo: {
      isPayNow: number; // 是否签约后立即扣费 1-是 2-否;
      delayPayTime: number; // 延迟扣费时间;
      delayUnit: number; // 时间单位 1 天 2 月;
    };
    paymentPeriodType: number; // 缴费期限类型 1-天 2-月 3-年 4-至某周岁 5-趸交;
    paymentPeriod: number; // 缴费期限 paymentPeriodType为5时默认传0;
    generateType: number; // 承保类型 1-签约后承保（先享后付产品） 2-缴费成功后承保（纯签约延迟扣费产品）;
    firstGraceDays: number; // 首期宽限期 单位天;
  };
  premiumInfo: {
    preminuMethod: number; // 算费方式 1-保司算费 2-元保算费;
    preminuRateOssUrl: string; // 费率表上传的地址;
    preminuRateType: number; // 费率类型 1-标准费率 2-阶梯费率;
    preminuSingleDiscount: number; // 趸缴是否支持折扣 1-是 2-否;
    premiumRuleVo: {
      paymentTypes: string; // 缴费方式 -1:无关 1：月缴 2：年缴;
    };
  };
  refundInfo: {
    isRefundOnline: number; // 是否支持线上退保;
    refundAcc: number; // 是否支持通融退保;
    refundMethods: Array<string>; // 线上退保方式 (支持多选)  1-API 2-H5 3-电话退保 4-线下退保;
  };
  renewalInfo: {
    againInsureEntrance: Array<number>; // 重新投保入口;
    aheadDay: number; // 续保提前日期;
    autoRenewalEntrance: Array<number>; // 自动续保入口;
    changePlan: number; // 是否支持变更方案;
    changeSocial: number; // 是否支持变更社保;
    graceDay: number; // 续保提前日期;
    manualRenewalEntrance: Array<number>; // 手动续保入口;
    renewalType: number; // 续保方式 1-自动和手动续保 2-重新投保;
    xbInsuranceList: Array<{
      0: {
        xbInsuranceId: string; // 续保产品id;
        xbInsuranceName: string; // 续保产品名称;
      };
    }>;
    contractsXb: Array<{
      key: string; // 保险协议标题;
      value: string; // 保险协议id;
    }>;
    isRenewal: number; // 是否续保产品 1-是 2-否;
  };
  version: number; // 版本号;
  landingPageVo: {
    insuranceId: string; // 产品id;
    landingPageVersion: number; // 版本号;
    landingPageRemark: string; // 版本说明;
    pageId: string; // 落地页配置id;
    operator: string; // 操作人;
    otherInfo: any;
    pageInfo: any;
  };
  pageId: string; // 落地页配置id;
  landingPageRemark: string; // 版本说明;
  upgradeBaseInfo: {
    applicantAgeRuleVo: {
      calculateBase: number; // 投保人年龄计算基准 1-投保日 2-保单生效日，默认 投保日;
      endType: number; // 最大年龄类型 1-天 2-周岁 3-不限制;
      endValue: number; // 最大年龄;
      startType: number; // 最小年龄类型 1-天 2-周岁;
      startValue: number; // 最小年龄;
    };
    applicantInsurantRelations: Array<string>; // 投被保人关系类型 (多选)，1-本人 2-父母 3-子女 4-配偶 5-雇佣 6-抚养 7-赡养 8-其他，默认本人;
    businessRuleVo: {
      firstBusinessType: number; // 一级业务类型;
      secondBusinessType: number; // 二级业务类型;
      thirdBusinessType: number; // 三级业务类型;
    };
    companyId: number; // 保险公司枚举id;
    companyName: string; // 保险公司名称;
    createBy: string; // 创建人;
    crossPayRuleVo: {
      crossPayDay: number; // 跨天支付天;
    };
    effectiveRuleVo: {
      effectiveDay: number; // 保单生效时间;
    };
    firstInsuranceType: string; // 新保险类型 一级 1-赠险 2-付费险;
    graceRuleVo: {
      graceDay: number; // 缴费宽限期;
      includePayDay: number; // 缴费宽限期是否包含缴费日 1-是 2-否;
      effectDateRule: number; // 当月无生效日时缴费日 1 - 当月最后一天（默认选项） 2 - 次月第一天;
    };
    hesitationRuleVo: {
      hesitationDay: number; // 退保犹豫期;
    };
    insuranceId: string; // 产品id;
    insuranceInfo: string; // 产品说明;
    insuranceType: string; // 保险类型 1-医疗、2-意外、3-重疾、4-人寿、5-年金、6-财产、7-赠险、8-投连;
    insurantAgeRuleVo: {
      calculateBase: number; // 被保人年龄计算基准 1-投保日 2-保单生效日，默认 保单生效日;
      endType: number; // 最大年龄类型 1-天 2-周岁 3-不限制;
      endValue: number; // 最大年龄;
      startType: number; // 最小年龄类型 1-天 2-周岁;
      startValue: number; // 最小年龄;
    };
    insurePeriod: number; // 保障天数;
    insurePeriodType: number; // 保障期限类型 1-一年以下 2-一年期 3-长期;
    isBirthday: number; // 生日当天是否满周岁 1-是 2-否 默认是，即生日当天算满周岁;
    isBw: number; // 是否百万保险;
    isCrossPay: number; // 是否支持跨天支付;
    isSocialInsurance: number; // 是否限制有社保;
    jobRuleVo: {
      endJobValue: number; // 结束值;
      startJobValue: number; // 开始值;
    };
    productAbb: string; // 产品简称;
    productId: string; // 保司产品id 多个用英文逗号隔开;
    productName: string; // 保司产品名称;
    productUsage: number; // 产品用途 1-线上使用 2-销售使用 默认：线上使用;
    saleName: string; // 产品销售名称;
    secondInsuranceType: string; // 新保险类型 二级 1-医疗、2-意外、3-重疾、4-人寿、5-年金、6-财产、8-投连;
    status: number; // 产品状态 1-草稿中、2-待上架、3-已上架、4-约定上架中、5-已下架、6-约定下架中;
    thirdInsuranceType: string; // 新保险类型 三级 待确定;
    uneffectiveRuleVo: {
      uneffectiveDay: number; // 未缴费保单失效时间 1 - 缴费日00:00:00；2- 宽限期最后一天23:59:59；3- 宽限期最后一天次日00:00:00;
    };
    updateBy: string; // 修改人;
    limitType: number; // 保险期限 1-短期险 2-长期险;
    channel: number; // 产品渠道;
    connectType: number; // 对接方式 1-API对接 2-H5对接;
    productUrl: string; // H5产品链接;
    connectionParams: string; // 对接参数;
    routingRuleVo: {
      requireRouting: number; // 是否需要路由 0 否 1 是（isBw=0不填，isBw=1 必填）;
      routingRule: number; // 路由规则 0 路由来 1 路由走 （requireRouting=0不填，requireRouting=1 必填）;
      destination: string; // 路由目标 目标保险id;（routingRule=0 不填，routingRule=1 必填）;
    };
  };
  upgradeProductPageMaterialVo: {
    initiativePayDoc: string; // 主动支付文案;
    pageInsuranceDuty: number; // 页面保障责任默认收起 1-展示 2-收起;
    payNextPremiumBtn: number; // 是否支持支付全年保费 1-是 2-否;
    payYearPremiumBtn: number; // 是否支持支付全年保费 1-是 2-否;
    productPlanList: Array<{
      0: {
        planId: string; // 方案Id;
        planName: string; // 方案名称;
        productPlanDutyList: Array<{
          0: {
            desc: string; // 条款解读;
            dutyId: string; // 责任id;
            dutyName: string; // 责任名称;
            insuredAmount: string; // 保额;
            isTotalInsuredAmount: number; // 是否计入总保额;
          };
        }>;
        contractsNew: Array<{
          key: string; // 保险协议标题;
          value: string; // 保险协议id;
        }>;
        contractsYBaoBx: Array<{
          key: string; // 保险协议标题;
          value: string;
        }>;
        contractsCn: Array<{
          key: string;
          value: string;
        }>;
        contractsTc: Array<{
          key: string;
          value: string;
        }>;
        contractsTcOut: Array<{
          key: string;
          value: string;
        }>;
        contractsDsCn: Array<{
          key: string;
          value: string;
        }>;
        productPlanId: string; // 保司方案id;
        compensateJson: Array<{
          key: string; // 标题;
          value: string; // 内容;
        }>;
        processJson: Array<{
          key: string;
          value: string;
        }>;
        qaJson: Array<{
          key: string;
          value: string;
        }>;
        qaLandingPage: Array<{
          key: string;
          value: string;
        }>;
        monthMinPremium: undefined; // 月保费最小费率;
        yearMinPremium: undefined; // 年保费最小费率;
        deductibles: string; // 免赔额;
        compensationRatio: string; // 赔付比例;
      };
    }>;
    pagePremiumMethod: number; // 页面费率配置方式 1-文字 2-上传图片;
    premiumMonthPay: string; // 页面费率表信息（月缴）;
    premiumYearPay: string; // 页面费率表信息（年缴）;
    premiumInfoImg: string; // 页面费率表信息图;
    premiumDoc: string; // 完整费率文案;
    isPremiumUpload: number; // 是否费率表上传 1-不上传 2-为上传;
    unpaidEntrance: string; // 待支付入口 格式：{"WECHAT":1,"WECHAT_MINI_APP":1,"KUAISHOU_MINI_APP":1,"DOUYIN_MINI_APP":1}，其中0为未勾选，1为勾选;
  };
  icpContractCopy: string; // 协议文案;
  appendIcpContractCopy: string; // 拼接协议文案;
  groupOrderStartTime: number; // 拼团下单活动开始时间;
  groupOrderEndTime: number; // 拼团下单活动结束时间;
  groupOrderStartNum: number; // 拼团下单活动开始人数;
  icpContractCopyPost: null; // 批增后置、均价ipc协议;
  icpContractCopyPre: null; // 批增前置ipc协议;
}
