/*
 * @Author: cuilinchao
 * @Date: 2024-11-14 17:39:05
 * @LastEditors: cuilinchao
 * @LastEditTime: 2024-11-19 16:16:49
 * @Description: 风险测评-路由
 */
export default {
  path: '/risk',
  routes: [
    {
      path: '/risk/result',
      component: () => import('./result'),
    },
    {
      path: '/risk/question',
      component: () => import('./question'),
    },
    {
      path: '/risk/list',
      component: () => import('./list'),
      title: '历史报告',
    },
  ],
};
