/*
 * @Author: shixiaoxia
 * @Date: 2024-08-30 10:20:50
 * @LastEditors: liulongjie liulongjie@yuanbaobaoxian.com
 * @LastEditTime: 2024-11-18 14:04:34
 * @Description: gift/middle
 */
import { isRequestError } from '../../../utils/httperr';
import { CommonHeader } from '../../../fetch/base';
import {
  getBffStartData,
} from '../../../fetch/bff';
import { getLogger } from '../../../webLogger';
import { getShuntAbtestAll } from '../../../fetch/shunt';
import { GiftMiddleRequestBody } from './GiftMiddleRequestBody';
import { GiftMiddleResult, PrefixAbResultTemplates } from './type';

export * from './type';
export { GiftMiddleRequestBody } from './GiftMiddleRequestBody';
export { GiftMiddleAbTestParams } from './GiftMiddleAbTestParams';
import { CommonParams } from '../../../types';

/**
 * 获取gift/middle页的 start 与 前置分流的聚合结果
 * @param params {GfitMiddleRequestBody}
 * @param commonHeaders {CommonHeader}
 * @returns {Promise<GfitMiddleResult>}
 */
export async function giftMiddleService(params: GiftMiddleRequestBody & CommonParams, commonHeader: CommonHeader): Promise<GiftMiddleResult> {
  const {
    startParams,
    abtestParams,
    commonApiParams,
    commonAbTestParams,
  } = params;
  const { shuntId } = commonApiParams;
  const { ybPosition } = abtestParams;

  // 获取start数据
  const startDataResponse = await getBffStartData(startParams, commonApiParams, commonHeader);
  if (isRequestError(startDataResponse?.data)) {
    getLogger().warn('failed', { location: 'giftService:getBffStartData', url: startDataResponse.config.url });
    return {
      startData: startDataResponse?.data,
    };
  }
  // 前置分流参数
  const { initInfo, userInfo } = startDataResponse?.data?.data || {};
  commonApiParams.tuiguangId = initInfo?.mediaUuid || '';
  const { unionId = '' } = userInfo || {};
  commonApiParams.unionId = unionId;

  // 赠险前置请求
  const abTestparams = [
    { configCode: 'giftShuntAbtest', extraLimit: { shuntId } },
  ];
  const prefixABTestDataResponse = await getShuntAbtestAll<PrefixAbResultTemplates>(ybPosition, abTestparams, commonAbTestParams, commonApiParams, commonHeader);
  return {
    startData: startDataResponse?.data,
    prefixABTestData: prefixABTestDataResponse?.data,
  };
}
