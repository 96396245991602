/*
 * @Author: xiaotao xiaotao@yuanbaobaoxian.com
 * @Date: 2024-11-15 16:22:50
 * @LastEditors: xiaotao xiaotao@yuanbaobaoxian.com
 * @LastEditTime: 2024-11-28 19:23:54
 * @FilePath: /fe-insurance-web/packages/fe-main-web/src/component/NewProtect/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * 授权登录弹窗内容
 */
import React, { Fragment } from 'react';
import { YBModal } from 'component';
import { trackEvent } from 'ybcommon/ybutils/statistic';
import classNames from 'classnames';
import styles from './index.less';

const SECTABKEY = 'plan-item';

interface IProps {
  version: number; // 渲染的版本
  productPlanList: Array<any> // 保障方案数组
  planId: string; // 当前选中的方案Id
  onClickPlan: (index: number) => void; // 点击事件回调
}
interface IState {
  dutyList: Array<any>; // 险种责任数组
  visible: boolean; // 是否展示弹框
  selectItem: any; // 选中的险种责任
  selectedIndex: number; // 当前选中的保障方案
}

class NewProtect extends React.Component<IProps, IState> {
  planNames: Array<string>; // 计划名称

  tabsPosition: Array<any>; // 元素坐标

  scrollRef: any; // 滚动元素的ref

  constructor(props) {
    super(props);
    this.tabsPosition = [];
    const { selectedIndex, planNames } = this._getSelectIndexAndPlanNames();
    this.planNames = planNames;
    const dutyList = this._getDutyList(selectedIndex);
    this.state = {
      visible: false,
      selectItem: null,
      selectedIndex,
      dutyList,
    };
  }

  componentDidMount(): void {
    const { version } = this.props;
    if (version > 1 && !!this.planNames?.length) {
      this._getTabsPosition();
    }
  }

  /**
   * @description: 获取当前选中方案的下标
   * @return {*}
   */
  _getSelectIndexAndPlanNames = () => {
    const planNames: Array<string> = [];
    const { productPlanList, planId } = this.props;
    let selectedIndex = 0;
    productPlanList.forEach((element, index) => {
      planNames.push(element.planName);
      if (planId === element.planId) selectedIndex = index;
    });
    return { selectedIndex, planNames };
  };

  /**
   * @description: 获取险种责任数据
   * @param {*} planId
   * @return {*}
   */
  _getDutyList = (selectedIndex) => {
    const { productPlanList } = this.props;
    const plan = productPlanList[selectedIndex];
    return (plan && plan.productPlanDutyList?.filter((item) => +item.dutyType !== 2)) || [];
  };

  /**
* @description:获取每个分类当前的位置
* @return {*}
*/
  _getTabsPosition() {
    const itemEls = document.querySelectorAll(`.${SECTABKEY}`) || [];
    itemEls.forEach((el) => {
      this.tabsPosition.push(el);
    });
  }

  /**
  * @description: 点击险种计划
  * @param {*} index
  * @param {*} isClick
  * @return {*}
  */
  _handleIndexChange = (index) => {
    const { onClickPlan } = this.props;
    if (onClickPlan) onClickPlan(index);
    const dutyList = this._getDutyList(index);
    const planName = this.planNames[index];
    trackEvent('insurancedetail_supportplan_plan_click', { plan: planName });
    this._updateActiveTab(index);
    this.setState({
      selectedIndex: index,
      dutyList,
    });
  };

  /**
* @description: 更新处于激活状态的tab
* @return {*}
*/
  _updateActiveTab(index) {
    if (!this.tabsPosition.length) return;
    const itemEl = this.tabsPosition[index];
    if (!itemEl) return;
    const offsetLeft = itemEl.offsetLeft;
    const itemWidth = itemEl.offsetWidth;
    const windowWidth = window.screen.width;
    let distance = 0;
    if (offsetLeft > windowWidth / 2) {
      distance = offsetLeft + itemWidth / 2 - windowWidth / 2;
    }
    this.scrollRef.scrollTo({
      left: distance,
      behavior: 'smooth',
    });
  }

  /**
   * @description: icon图标点击事件
   * @return {*}
   */
  _handleIconClick = (item) => {
    const { insuranceTypeDutyDesc } = item || {};
    if (!insuranceTypeDutyDesc) return;
    const { selectedIndex } = this.state;
    const planName = this.planNames[selectedIndex];
    trackEvent('insurancedetail_supportplan_plandetailpop_show', { plan: planName, content: item.dutyName });
    this.setState({
      visible: true,
      selectItem: item,
    });
  };

  /**
   * @description: 关闭弹框
   * @return {*}
   */
  _handleCloseModal = (num) => {
    const { selectedIndex, selectItem } = this.state;
    const planName = this.planNames[selectedIndex];
    trackEvent('insurancedetail_supportplan_plandetailpop_click', { plan: planName, content: selectItem.dutyName, btn: num });
    this.setState({
      visible: false,
    });
  };

  _renderModal = () => {
    const { selectItem, visible } = this.state;
    const { insuranceTypeDutyDesc, dutyName } = selectItem || {};
    return (
      visible ? (
        <YBModal
          visible={visible}
          popup
          maskClosable
          transparent={true}
          animationType="slide-up"
          onMaskClick={() => this._handleCloseModal(1)}
        >
          <div className={styles.modalContainer}>
            <div className={styles.modalWrapper}>
              <div className={styles.content}>
                <p className={styles.title}>产品解答</p>
                <div className={styles.topWrapper}>
                  <img src={require('./img/tip.png')} alt="" className={styles.tip} />
                  <span className={styles.subTitle}>{dutyName}</span>
                </div>
                <p className={styles.desc}>{insuranceTypeDutyDesc}</p>
                <p className={styles.btn} onClick={() => this._handleCloseModal(0)}>我知道了</p>
              </div>
            </div>
          </div>

        </YBModal>
      ) : null
    );
  };

  /**
   * @description: 渲染版本1
   * @return {*}
   */
  _renderVersion1 = () => {
    const { dutyList = [] } = this.state;
    return (
      <div className={styles.container1}>
        {
          dutyList?.map((item, index) => {
            const { insuranceTypeDutyDesc } = item || {};
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div className={styles.item} key={index}>
                <div className={styles.itemLeft} onClick={() => this._handleIconClick(item)}>
                  <span className={styles.value}>{item.dutyName}</span>
                  {!!insuranceTypeDutyDesc?.length && <img src={require('./img/icon.png')} alt="" className={styles.icon} />}
                </div>
                <div className={styles.itemRight}>
                  <span className={styles.value}>{item.insuredAmount}</span>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };

  /**
   * @description: 渲染版本2
   * @return {*}
   */
  _renderVersion2 = () => {
    const { selectedIndex, dutyList } = this.state;
    return (
      <div className={styles.container2}>
        <div ref={(e) => this.scrollRef = e} className={styles.scrollWrapper}>
          {
            this.planNames?.map((item, index) => {
              const isActive = selectedIndex === index;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className={classNames(styles.planItemWrapper, `${SECTABKEY}`, { [styles.activeItem]: isActive })} key={index} onClick={() => this._handleIndexChange(index)}>
                  <span>{item}</span>
                  {isActive && <img className={styles.arrow} src={require('./img/arrow.png')} alt="" />}
                </div>
              );
            })
          }
        </div>
        <div className={styles.content}>
          {
            dutyList?.map((item, index) => {
              const { insuranceTypeDutyDesc } = item || {};
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className={styles.item} key={index}>
                  <div className={styles.itemLeft} onClick={() => this._handleIconClick(item)}>
                    <span className={styles.value}>{item.dutyName}</span>
                    {!!insuranceTypeDutyDesc?.length && <img src={require('./img/icon.png')} alt="" className={styles.icon} />}
                  </div>
                  <div className={styles.itemRight}>
                    <span className={styles.value}>{item.insuredAmount}</span>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  };

  render() {
    const { version } = this.props;
    return (
      <Fragment>
        {version > 1 ? this._renderVersion2() : this._renderVersion1()}
        {this._renderModal()}
      </Fragment>
    );
  }
}

export default NewProtect;
