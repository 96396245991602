/* eslint-disable no-continue */
import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { cloneDeep } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import { pxTOrem } from 'ybcommon/ybutils/common/common';
import { autoBackHistory, setCanUseLocalBackListener } from 'ybcommon/ybutils/common/backutil';
import ProtocolModalManager from 'src/sys/components/ProtocolModalManager';
import styles from './index.less';

const TABNAMEMAP = {
  0: '保险条款',
  1: '重要协议',
  2: '隐私政策',
};

export const FoldType = {
  NotFold: 0, // 不折叠
  Fold: 1, // 折叠
  FoldOptimize: 2, // 折叠优化
  FoldOptimizeAndPrivacy: 3, // 折叠优化+隐私政策
  FoldOptimizeAndProtocalAndPrivacy: 4, // 折叠优化+仅展示保险条款、隐私政策
};
@inject('userStore', 'toolStore')
@observer
class Protocal extends Component {
  static defaultProps = {
    isExpandCheckHotZone: false, // 是否扩大勾选热区
    isForceProtocolModalCB: false, // 是否强制每次折叠协议弹窗回调都要执行
    proContentClassName: null, // 协议的类名
    isFold: 0, // 协议展示类型 0:版本吧1-普通 2:版本3-折叠优化版 3:版本4-折叠优化+隐私政策 4: 版本4基础上+仅展示保险条款、隐私政策
    isFoldNormalStyle: false, // 是否展示普通协议非折叠展示样式(相同协议名称合并展示) isFold大于0时生效
    bottomExtContent: null, // 协议底部扩展内容
    featureUrlJson: [], // 普通协议
    extraProtocalList: [], // 扩展协议（展示在普通协议后面）
    flodExtraProtocalList: [], // 折叠优化的时候扩展协议（展示在保险条款下面）
    extraNoFoldProtocalList: [], // 需要另外展示的非折叠协议(如果需要结尾展示的必显协议，请用extraLastProtocalList)
    extraLastProtocalList: [], // 需要另外展示的结尾协议（不会被折叠，必显协议）
    styleType: undefined, // 协议名样式 默认书名号 1-中间｜分割
    /**
     * 这个回调只会在_nextPageClickModal触发
     * 不是真正的协议弹窗展示回调
     * 真正的回调方法onShowProtocalModal
     */
    onShowProtocal: undefined, // 展示协议弹窗的回调
    onCloseProtocolModal: undefined, // 关闭协议弹窗的回调
    /* 真正的协议弹窗展示回调 */
    onShowProtocalModal: undefined,
    protocalType: '', // 协议类型 common-普通协议 update-升级协议
    showPage: '', // 展示页面 目前仅有链路3次页2-'link3Detail2'
    isCombineProtocol: false, // 是否是组合险种 协议
    isCouple: false, // 是否是均分组合纬度 协议
    isShowEndContent: false, // 是否展示协议后缀内容
    autoKeyBoardIntercept: false, // 自动聚集身份证输入框时是否阻止其他模块的点击
    hideCheckBox: false, // 隐藏协议勾选框
    showOptimizeModal: null, // 自定义展示折叠协议弹窗 参数：(protocols--所有协议集合, tab)
    /** 协议后缀文案 */
    suffixContent: '',
  };

  constructor(props) {
    super(props);
    const { isFoldNormalStyle, isFold } = props;
    if (isFoldNormalStyle && isFold) {
      this._handleProtocolList();
      this.protocolNameList = this._handleProtocolNameList(this.protocolList);
    }
  }

  state = {
  };

  /* 是否展示协议弹窗 */
  isShowProtocolModal = false;

  static unfoldProtocalItem = {};

  _handleCombineProtocolMap = (map, key, protocol) => {
    if (map[key]) {
      map[key].push(protocol);
    } else {
      map[key] = [protocol];
    }
  };

  // 处理普通协议非折叠展示样式(相同协议名称合并展示)
  _handleProtocolNameList = (protocolList) => {
    const result = [];
    if (protocolList?.length > 0) {
      protocolList.forEach((item, i) => {
        if (!item?.length) return;
        item.forEach((item2) => {
          if (item2?.list?.length > 0) { // 是否有多个协议
            result.push({ ...item2, _index: i, isOptimize: true });
          } else {
            result.push(item2);
          }
        });
      });
    }
    return result;
  };

  /**
   * 协议展示优化，处理组合险种协议列表
   * 组合险不会出现 isFold === 4 不处理
   */
  _handleCombineProtocolList = (data) => {
    const { isFold } = this.props;
    const protocolList = [[], [], []];
    const maps = [{}, {}, {}];
    let insuranceClause;
    for (const protocol of (data || [])) {
      switch (+protocol.type) {
        case 0: // 协议类型为保险资料
          protocolList[0].push(protocol);
          this._handleCombineProtocolMap(maps[0], protocol.key, protocol);
          break;
        case 1: // 协议类型为保险协议
          protocolList[1].push(protocol);
          this._handleCombineProtocolMap(maps[1], protocol.key, protocol);
          break;
        case 4: // 协议类型为隐私政策
          if (isFold === 2) { // 版本3-折叠优化版 重要协议取 保险协议+隐私政策
            protocolList[1].push(protocol);
            this._handleCombineProtocolMap(maps[1], protocol.key, protocol);
          } else if (isFold === 3) { // 版本4-折叠优化+隐私政策 隐私政策取cms中隐私政策
            protocolList[2].push(protocol);
            this._handleCombineProtocolMap(maps[2], protocol.key, protocol);
          } else { // 兜底
            protocolList[0].push(protocol);
            this._handleCombineProtocolMap(maps[0], protocol.key, protocol);
          }
          break;
        case 5: // 协议类型为保险条款
          insuranceClause = protocol; // 先放到临时对象中，最后再塞到数组最前面
          this._handleCombineProtocolMap(maps[0], protocol.key, protocol);
          break;
        default: // 兜底
          protocolList[0].push(protocol);
          this._handleCombineProtocolMap(maps[0], protocol.key, protocol);
          break;
      }
    }
    // if (isFold === 2 || isFold === 3) this.protocolList[0] = this.protocolList[0].concat(flodExtraProtocalList);
    if (insuranceClause) protocolList[0].unshift(insuranceClause); // 保险条款必须排在第一行

    return [protocolList, maps];
  };

  /**
   * 处理组合险种协议
   */
  _handleCombineProtocol = () => {
    const { featureUrlJson, isCouple } = this.props;
    const data = cloneDeep(featureUrlJson);
    const long = [];
    const gift = [];

    // console.log('featureUrlJson===', featureUrlJson);

    for (const protocol of data) {
      if (protocol.isGift || (isCouple && protocol.isCouple)) {
        gift.push(protocol);
      } else {
        long.push(protocol);
      }
    }

    const [gLists, gMaps] = this._handleCombineProtocolList(gift);
    const [lLists, lMaps] = this._handleCombineProtocolList(long);

    this.protocolList = [[], [], []];

    for (let i = 0; i < 3; i++) {
      const list = this.protocolList[i];
      const gList = gLists[i];
      const gMap = gMaps[i];
      const lList = lLists[i];
      const lMap = lMaps[i];
      const map = {};

      // console.log('before map===', lList, gList);

      for (const protocol of lList) {
        const { key, productName } = protocol;

        if (isCouple && i) continue;

        if (map[key]) continue;

        map[key] = 1;

        if (gMap[key] && !i) {
          map[key] += 1;
          // eslint-disable-next-line no-shadow
          const data = {
            key,
            list: [
              {
                key: `${productName}${key}`,
                defaultKey: key,
                list: lMap[key],
              },
              {
                key: `${gMap[key][0].productName}${key}`,
                defaultKey: key,
                list: gMap[key],
              },
            ],
          };
          if (lMap[key].length === 1) {
            data.list[0] = protocol;
            protocol.key = `${productName}${key}`;
            protocol.defaultKey = key;
          }
          if (gMap[key].length === 1) {
            data.list[1] = gMap[key][0];
            data.list[1].defaultKey = key;
            data.list[1].key = `${gMap[key][0].productName}${key}`;
            map[data.list[1].key] = 2;
          }
          list.push(data);
        } else if (lMap[key].length > 1) {
          if (isCouple) {
            list.push(...lMap[key]);
          } else {
            list.push([{
              ...protocol,
              list: lMap[key],
            }]);
          }
        } else {
          list.push(protocol);
        }

        if (i && gMap[key]) {
          map[key] += 1;
        }
      }

      // console.log('after map===', gList);

      for (const protocol of gList) {
        const { key } = protocol;

        if (isCouple && i) {
          list.push(protocol);
          continue;
        }

        if (map[key] >= 2) continue;

        map[key] = 2;

        if (gMap[key]?.length > 1) {
          if (isCouple) {
            list.push(...gMap[key]);
          } else {
            list.push([{
              ...protocol,
              list: gMap[key],
            }]);
          }
        } else {
          list.push(protocol);
        }
      }
    }
  };

  /**
   * 协议展示优化，处理协议列表
   */
  _handleProtocolList = () => {
    const { featureUrlJson = [], isFold, flodExtraProtocalList, isCombineProtocol } = this.props;

    if (isCombineProtocol) {
      this._handleCombineProtocol();
      return;
    }

    this.protocolList = [[], [], []];
    let insuranceClause;
    for (const protocol of (featureUrlJson || [])) {
      switch (+protocol.type) {
        case 0: // 协议类型为保险资料
          this.protocolList[0].push(protocol);
          break;
        case 1: // 协议类型为保险协议
          if (isFold !== 4) {
            this.protocolList[1].push(protocol);
          }
          break;
        case 4: // 协议类型为隐私政策
          if (isFold === 2) { // 版本3-折叠优化版 重要协议取 保险协议+隐私政策
            this.protocolList[1].push(protocol);
          } else if (isFold === 3) { // 版本4-折叠优化+隐私政策 隐私政策取cms中隐私政策
            this.protocolList[2].push(protocol);
          } else if (isFold === 4) { // 版本5-版本4基础+不展示重要协议
            this.protocolList[1].push(protocol);
          } else { // 兜底
            this.protocolList[0].push(protocol);
          }
          break;
        case 5: // 协议类型为保险条款
          // notFold 协议不折叠，不用放到最前面
          if (!protocol.notFold) {
            insuranceClause = protocol; // 先放到临时对象中，最后再塞到数组最前面
          }
          break;
        default: // 兜底
          this.protocolList[0].push(protocol);
          break;
      }
    }
    if (isFold === 2 || isFold === 3 || isFold === 4) this.protocolList[0] = this.protocolList[0].concat(flodExtraProtocalList);
    if (insuranceClause) this.protocolList[0].unshift(insuranceClause); // 保险条款必须排在第一行
  };

  setChecked = (checked) => {
    const { onCheckChange, showCheckIcon = true } = this.props;
    if (onCheckChange && showCheckIcon) {
      onCheckChange(checked);
    }
  };

  /**
   * 点击展示协议优化弹窗回调
   * @param {number} tabVal 弹窗tab值。 0 - 保险条款，1 - 服务条款
   * @param {string | undefined} protocalTitle 协议名称
   * {Array<{ key: string, value: string }>[]} protocolList 弹窗协议列表
   */
  _showOptimizeModal = (e, tabVal, protocalTitle) => {
    const {
      isFold,
      protocalMask,
      onCloseProtocolModal,
      autoKeyBoardIntercept,
      showOptimizeModal,
      refClassName,
      toolStore,
      onStatic,
      protocalType,
      isCombineProtocol,
      autoScrollToSubMenu,
      hideTab,
    } = this.props;
    if (e) {
      e.stopPropagation();
    }

    if (protocalMask) return;
    if (autoKeyBoardIntercept) return;
    let title = TABNAMEMAP[tabVal];
    if (tabVal === 1 && isFold === 4) { // 隐私政策在第二位
      title = TABNAMEMAP[2];
    }
    /* 触发协议弹窗展示回调 */
    this._showProtocolModalCB(title);
    this.isShowProtocolModal = true;

    this._handleProtocolList();
    if (showOptimizeModal) {
      // 自定义展示折叠协议弹窗
      showOptimizeModal(this.protocolList, tabVal, title);
    } else {
      let protocolList = this.protocolList;
      // 如果隐藏Tab，则把协议都合并到一起
      if (hideTab) {
        const combineList = [...(this.protocolList[0] || []), ...(this.protocolList[1] || []), ...(this.protocolList[2] || [])];
        protocolList = [combineList, combineList, combineList];
      }
      toolStore.showOptimizeModal({
        tabVal,
        protocolList,
        refClassName,
        isCombineProtocol,
        onStatic,
        protocalType,
        isFold,
        protocalTitle,
        scrollToActive: autoScrollToSubMenu,
        hideTab,
        onClose: () => {
          onCloseProtocolModal?.();
        },
      });
    }

    if (onStatic) onStatic('h5_cargodetail_xieyiliebiao3_show', { title }); // 浏览协议列表弹窗
  };

  /* 展示协议弹窗的回调 */
  _showProtocolModalCB = (title, id) => {
    const { onShowProtocalModal, isForceProtocolModalCB } = this.props;
    if (this.isShowProtocolModal && !isForceProtocolModalCB) {
      /* 已经展示了，不再次出发回调 */
      return;
    }
    if (onShowProtocalModal) onShowProtocalModal(title, id);
  };

  /**
   * 打开单个协议
   * @param {*} e
   * @param {*} title 协议名称
   * @param {*} path 协议id
   * @param {*} version 协议版本
   * @returns
   */
  _showSingleProtocal = (e, title, path, version) => {
    const { protocalMask, autoKeyBoardIntercept, protocalType, onStatic } = this.props;
    e.stopPropagation();

    if (protocalMask) return;
    if (autoKeyBoardIntercept) return;
    /* 触发协议弹窗展示回调 */
    this._showProtocolModalCB(title, path);
    this.isShowProtocolModal = true;

    ProtocolModalManager.show({
      protocalTitle: title,
      protocolId: path,
      protocolVersion: version,
    });
    if (protocalType && onStatic) { onStatic('h5_cargodetail_xieyixiangqing_show', { agreement_ID: path, agreement_place: protocalType || '' }); }
  };

  /**
   * 协议点击
   * @param {*} e
   * @param {*} title 协议名称
   * @param {*} path 协议id
   * @param {*} isUnfoldProtocalModal 是否非折叠协议弹窗
   * @param {*} contentStyle 协议弹窗样式
   * @returns
   */
  _nextPageClickModal = (e, title, path, isUnfoldProtocalModal, contentStyle, protocalVersion) => {
    e.stopPropagation();
    const { onShowProtocal, toolStore, needPushPath = false, showProtocalConfirm, protocalMask, onCloseProtocolModal } = this.props;

    if (protocalMask) return;
    /* 触发协议弹窗展示回调 */
    this._showProtocolModalCB(title, path);
    this.isShowProtocolModal = true;

    toolStore.showModalByType(
      18,
      {
        type: 3,
        protocalTitle: title,
        protocolId: path,
        onClose: () => {
          if (needPushPath) {
            setCanUseLocalBackListener(false);
            autoBackHistory();
          }
          if (isUnfoldProtocalModal) {
            this._showSubMenuModal();
          } else {
            onCloseProtocolModal?.();
            showProtocalConfirm?.();
          }
        },
        contentClassName: contentStyle,
        protocalVersion,
        useYBModal: true,
      },
      needPushPath,
    );
    if (onShowProtocal) onShowProtocal(title, path);
  };

  _showSubMenuModal = (e, item) => {
    const { toolStore, showProtocalConfirm, onShowProtocal } = this.props;
    if (item) this.unfoldProtocalItem = item;
    if (e) e.stopPropagation();
    item = item || this.unfoldProtocalItem;
    item.nextPageClickModal = this._nextPageClickModal;
    item.onCustomerClose = showProtocalConfirm;
    if (onShowProtocal) onShowProtocal(item?.title);
    toolStore.showModalByType(
      110,
      {
        useYBModal: true,
        ...item,
        onClose: () => {
          item.onClose?.();
        },
      },
      true, // needAutoPushPath
    );
  };

  // 点击协议前置文字
  _handlePreContentClick = () => {
    const { showCheckIcon = true, hideCheckBox, isExpandCheckHotZone } = this.props;
    // 不渲染勾选框 未扩大热区直接返回
    if (!showCheckIcon || hideCheckBox || !isExpandCheckHotZone) {
      return;
    }
    this._handleCheckChange();
  };

  // 勾选事件
  _handleCheckChange = () => {
    const { protocalMask, checked = false } = this.props;
    if (protocalMask) return;
    this.setChecked(!checked);
  };

  _renderCheck = () => {
    const { showCheckIcon = true, isCouple, circleCheck = true, circleCheckWhite = false, checked = false, checkClassName, checkClass, uncheckClass, lineHeight } = this.props;

    if (!showCheckIcon) return null;

    let className = checked ? styles.checkSquare : styles.uncheckSquare;
    if (circleCheck) className = checked ? styles.checkCircle : styles.uncheckCircle;
    if (checked && circleCheck && circleCheckWhite) className = styles.checkCircleWhite;
    const { hideCheckBox } = this.props;
    return (
      <div
        id="protocal_check_box" // 测试-自动化使用
        className={classnames(className, 'tx-sync-pointevent-none', checkClassName, checked ? checkClass : uncheckClass)}
        style={{
          width: isCouple ? `${pxTOrem(16)}rem` : lineHeight,
          height: isCouple ? `${pxTOrem(16)}rem` : lineHeight,
          marginRight: isCouple ? `${pxTOrem(3)}rem` : '',
          display: hideCheckBox ? 'none' : 'unset',
          borderColor: circleCheckWhite ? '#fff' : '',
        }}
        onClick={() => {
          this._handleCheckChange();
        }}
      />
    );
  };

  // 渲染协议的每一项
  _renderProtocalItem = (item, index) => {
    const { styleType, fontSize, color, preColor, showProtocalConfirm, protocalType, onStatic, protocalMask, autoKeyBoardIntercept } = this.props;
    return (
      <Fragment key={`${index + 1}`}>
        {(index !== 0) && styleType === 1 && !item.preText ? <a style={{ color }} rel="noopener noreferrer">|</a> : null}
        {item.preText ? <span style={{ fontSize, color: preColor }}>{item.preText}</span> : null}
        <a
          className={styleType === 1 && !item.preText && !item.posText ? styles.pro : ''}
          style={{ fontSize, color }}
          onClick={(e) => {
            if (protocalMask) return;
            if (autoKeyBoardIntercept) return;
            if (item.handleClick) {
              item.handleClick(showProtocalConfirm);
            } else if (item.type === 5 && item?.contractTerms?.length > 1) {
              // 当协议类型为保险条款(type为5)且子菜单数量超过1个时，唤起二级列表弹窗组件(因为是非折叠/普通协议)
              this._showSubMenuModal(e, item);
            } else {
              let contentStyle;
              if (item.type === 6) {
                contentStyle = styles.contentHeight;
              } else {
                // 协议配置增加样式
                contentStyle = item.contentStyle || null;
              }
              this._nextPageClickModal(e, item.key, item.value, false, contentStyle, item.version);
            }
            if (protocalType && onStatic) { onStatic('h5_cargodetail_xieyixiangqing_show', { agreement_ID: item.value || '', agreement_place: protocalType || '' }); }
          }}
          rel="noopener noreferrer"
        >
          {styleType === 1 ? '' : '《'}{item.title || item.key}{styleType === 1 ? '' : '》'}
        </a>
        {item.posText ? <span style={{ fontSize, color: preColor }}>{item.posText}</span> : null}
      </Fragment>
    );
  };

  /**
   * 渲染协议名称list
   */
  _renderProtocolNameList = () => {
    const {
      fontSize = '',
      color,
      isFold,
      isFoldNormalStyle,
      foldProtocalNotCanClick, // 目前仅针对投保协议版本12、13 普通协议勾选时 不能点开协议
    } = this.props;
    if (isFoldNormalStyle && this.protocolNameList?.length) {
      return this.protocolNameList.map((item, i) => (
        <Fragment key={`${i + 1}`}>
          {i === 0 ? null : (
            <a style={{ color }} rel="noopener noreferrer">|</a>
          )}
          <a
            className={styles.pro}
            style={{ fontSize, color }}
            onClick={(e) => {
              if (foldProtocalNotCanClick) return;
              if (item.isOptimize) {
                this._showOptimizeModal(e, item._index, item.key);
              } else if (item.useCustomHandleClick && item.handleClick) {
                // 如果协议选项设置了自定义点击事件，则优先执行点击事件（仅在https://yuanbaoshuke.feishu.cn/docx/BUO0dhZdRoLIROx3AM4cFX6tnMg需求中使用这个参数）
                item.handleClick();
              } else {
                this._showSingleProtocal(e, item.key, item.value, item.version);
              }
            }}
            rel="noopener noreferrer"
          >
            {item.key || item.title}
          </a>
        </Fragment>
      ));
    }
    return (
      <Fragment>
        <a className={styles.pro} style={{ fontSize, color }} onClick={foldProtocalNotCanClick ? () => { } : (e) => this._showOptimizeModal(e, 0)} rel="noopener noreferrer">
          保险条款
        </a>
        <a style={{ color }} rel="noopener noreferrer">|</a>
        {isFold !== 4 ? (
          <a className={styles.pro} style={{ fontSize, color }} onClick={foldProtocalNotCanClick ? () => { } : (e) => this._showOptimizeModal(e, 1)} rel="noopener noreferrer">
            重要协议
          </a>
        ) : (
          <a className={styles.pro} style={{ fontSize, color }} onClick={(e) => this._showOptimizeModal(e, 1)} rel="noopener noreferrer">
            隐私政策
          </a>
        )}
        {
          isFold === 3 && (
            <Fragment>
              <a style={{ color }} rel="noopener noreferrer">|</a>
              <a className={styles.pro} style={{ fontSize, color }} onClick={foldProtocalNotCanClick ? () => { } : (e) => this._showOptimizeModal(e, 2)} rel="noopener noreferrer">
                隐私政策
              </a>
            </Fragment>
          )
        }
      </Fragment>
    );
  };

  /**
   * 渲染协议优化展示ab测内容
   */
  _renderOptimizeProtocol = (protocalList) => {
    const {
      preContent = '我已确认并同意',
      suffixContent = '',
      preColor,
      fontSize = '',
      lineHeight = '',
      color,
      extraNoFoldProtocalList,
      extraProtocalList,
      extraLastProtocalList,
      userStore: { protocolPage },
      upContent,
      isECommerce,
      isCouple,
      isShowEndContent,
    } = this.props;
    if (!protocalList?.length) {
      return (
        <div className={styles.proContent} style={{ lineHeight }}>
          <div>
            <span onClick={this._handlePreContentClick} style={{ fontSize, color: preColor }}>{preContent}</span>
            <span onClick={this._handlePreContentClick} style={{ fontSize, color: preColor }}>{suffixContent}</span>
          </div>
        </div>
      );
    }

    // 平安健康、中华批增升级添加结尾授权文案
    let endContent = extraNoFoldProtocalList?.length && extraNoFoldProtocalList[0].title === '授权' ? extraNoFoldProtocalList.map(this._renderProtocalItem) : '';
    if (!endContent) endContent = extraProtocalList?.length && extraProtocalList[0].title === '授权' ? extraProtocalList.map(this._renderProtocalItem) : '';

    // 赠险协议结尾展示
    let extraGiftProtocol = '';
    if (protocalList.find((item) => item?.value === protocolPage.hkywGift.protocolId)) {
      extraGiftProtocol = (
        <span style={{ fontSize, color: preColor }}>
          并
          <a
            className={styles.pro}
            style={{ fontSize, color }}
            onClick={(e) => this._nextPageClickModal(e, '航空意外赠险', protocolPage.hkywGift.protocolId)}
            rel="noopener noreferrer"
          >
            授权
          </a>
          领取航空意外保障
        </span>
      );
    }
    // 不折叠的协议
    const notFoldProtocols = protocalList && protocalList.filter((item) => item.notFold);
    return (
      <div className={styles.proContent} style={{ lineHeight }}>
        <div>
          <span onClick={this._handlePreContentClick} style={{ fontSize, color: preColor }}>{preContent}</span>
          {this._renderProtocolNameList()}
          {isECommerce && upContent ? upContent : null}
          {
            notFoldProtocols && notFoldProtocols.length
              ? notFoldProtocols.map(((protocal) => this._renderProtocalItem(protocal)))
              : null
          }
          <span style={{ fontSize, color: preColor }}>{isCouple && !isShowEndContent ? '' : '协议'}{endContent}</span>
          {extraGiftProtocol}
          {extraLastProtocalList.map(this._renderProtocalItem)}
          <span style={{ fontSize, color: preColor }}>{suffixContent}</span>
        </div>
      </div>
    );
  };

  /**
   * 渲染正常协议展示内容
   * @param {*} protocalList 协议列表
   * @returns doms
   */
  _renderNormalProtocol = (protocalList) => {
    const {
      preContent = '我已确认并同意',
      suffixContent = '',
      preColor,
      fontSize = '',
      lineHeight = '',
      agreeTelAndVx,
      bottomExtContent,
      extraNoFoldProtocalList,
      extraLastProtocalList,
    } = this.props;
    return (
      <Fragment>
        <div className={styles.proContent} style={{ lineHeight }}>
          <div>
            <span onClick={this._handlePreContentClick} style={{ fontSize, color: preColor }}>{preContent}</span>
            {protocalList.map(this._renderProtocalItem)}
            {
              agreeTelAndVx && (
                <Fragment>
                  <br />
                  <span style={{ fontSize }}>我同意元保保险代理客服人员通过电话/微信联系</span>
                </Fragment>
              )
            }
            {extraNoFoldProtocalList.map(this._renderProtocalItem)}
            {extraLastProtocalList.map(this._renderProtocalItem)}
            <span style={{ fontSize, color: preColor }}>{suffixContent}</span>
          </div>
        </div>
        {bottomExtContent}
      </Fragment>
    );
  };

  // 协议
  _renderProtocal = () => {
    const {
      featureUrlJson = [],
      className,
      extraProtocalList = [],
      borderType = 0,
      isFold, // 协议展示类型 0:版本吧1-普通 2:版本3-折叠优化版 3:版本4-折叠优化+隐私政策
      proContentClassName,
      protocalType,
      showPage,
      extraJiyanProtocol = [],
    } = this.props;
    let proConStyle;
    switch (borderType) {
      case 1: // 底部有border
        proConStyle = styles.protocalContainer2;
        break;
      case 2: // 顶部有border
        proConStyle = styles.protocalContainer3;
        break;
      case 3: // 上下都有border
        proConStyle = styles.protocalContainer4;
        break;
      case 5: // 没有边框，背景浅蓝色
        proConStyle = styles.protocalContainer5;
        break;
      default: // 没有border
        proConStyle = styles.protocalContainer;
        break;
    }
    const protocalList = [...(extraJiyanProtocol || []), ...(featureUrlJson || []), ...(extraProtocalList || [])];
    return (
      <div
        id={showPage === 'link3Detail2' ? protocalType : ''}
        className={className ? `${proConStyle} ${className}` : proConStyle}
      >
        {this._renderCheck()}
        <div className={classnames(styles.proRight, proContentClassName)}>
          {
            isFold ? (
              this._renderOptimizeProtocol(protocalList)
            ) : (
              this._renderNormalProtocol(protocalList)
            )
          }
        </div>
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        {this._renderProtocal()}
      </Fragment>
    );
  }
}

export default Protocal;
