/*
 * @Author: shixiaoxia
 * @Date: 2024-05-18 10:22:10
 * @LastEditors: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @LastEditTime: 2024-11-06 20:02:55
 * @Description: 赠险链路服务
 */
import { AxiosResponse } from 'axios';
import { UserGroupResult, OrderPersonCheckResult } from '../../../../../fetch/bff/types';
import { CommonHeader, DataResponse } from '../../../../../fetch/base';
import { ifHasGiftOrder, getUserGroup, queryOrderHmaAcceptstatisticPersonCheck } from '../../../../../fetch/bff';
import { mallQueryDetail } from '../../../../../fetch/mallGateway';
import { getShuntAbtestAll } from '../../../../../fetch/shunt';
import { GiftDetailPfreeConfig2Result, GiftPfreeConfig2AbtestTemplates, PfreeGiftDetailParam } from '../types';
import { CommonParams } from '../../../../../types';
import { ENTITY_AND_PRODUCTID_MAP } from '../../../../../fetch/mallGateway/types';
import { GiftUnloginConfig2AbtestTemplates, GiftDetailUnLoginConfig2Result } from '../../unlogin/types';
import { userEntityAndProductEntityIsMatch } from '../../login/config2';

export const giftDetailPfreeConfig2Service = async (
  giftParam: PfreeGiftDetailParam & CommonParams,
  commonHeader: CommonHeader,
): Promise<GiftDetailPfreeConfig2Result | (GiftDetailUnLoginConfig2Result & { userGroupData?: DataResponse<UserGroupResult> })> => {
  const {
    entitySwitch,
    queryOrderParams,
    queryDetailParams,
    ifHasOrderParams,
    userGroupParams,
    abtestParams,
    commonApiParams,
    commonAbTestParams,
  } = giftParam;
  const { isWifi, productAbb, ybPosition, previewCode, lastVisitTime } = abtestParams || {};
  const abTestCommonParams = {
    productAbb,
    giftProductAbb: productAbb,
  };
  const jiYanLoginAndGiftReloginAndGiftFormParams = {
    ...abTestCommonParams,
    // 重新登录需要参数
    lastVisitTime,
    // 极验需要参数
    isWifi,
  };
  const requests = [];
  // shunt请求，分流
  /** 请求的所有AB测编码列表，请求时赋值，用于给分流结果异常的AB测兜底 */
  const loginAbTestParams = [
    { configCode: 'giftLinkAbtest', extraLimit: { ...abTestCommonParams, previewCode: previewCode ? +previewCode : null } },
    { configCode: 'giftFormAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
    { configCode: 'giftCertNoScaleAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
    { configCode: 'giftAudioAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftFormHeaderImgAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftLoginAndLandingLongImageAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftPage1Abtest', extraLimit: abTestCommonParams },
    { configCode: 'giftPage2Abtest', extraLimit: abTestCommonParams },
    { configCode: 'giftDetailBackAbtest', extraLimit: abTestCommonParams },
    { configCode: 'idCardAbtest', extraLimit: abTestCommonParams },
    { configCode: 'greetingVariantAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftProConfirmAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftProtocolAbtest', extraLimit: abTestCommonParams },
    { configCode: 'formPermanentResidentIDCardAbtest', extraLimit: abTestCommonParams },
    { configCode: 'linkConfidentialTextAbtest', extraLimit: abTestCommonParams },
    { configCode: 'icpStyleAbtest', extraLimit: abTestCommonParams }, // 页面底部ICP样式ab测
    { configCode: 'guaranteeGenerationStyleAbtest', extraLimit: abTestCommonParams },
    { configCode: 'encryptLoginAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftUnderFormSwiperAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
  ];
  let abDataArr = loginAbTestParams.map((item) => item.configCode);
  requests.push(getShuntAbtestAll<GiftPfreeConfig2AbtestTemplates>(ybPosition, loginAbTestParams, commonAbTestParams, commonApiParams, commonHeader));
  // 请求落地页详情
  requests.push(mallQueryDetail(queryDetailParams, commonApiParams, commonHeader));
  requests.push(getUserGroup(userGroupParams, commonApiParams, commonHeader));
  // 登陆态或者有加密userid，获取是否领取+家庭成员信息和领取状态（不进行合并是为了避免中间有请求失败导致接口异常）
  const safeGetOrderData = async () => {
    try {
      const res = await ifHasGiftOrder(ifHasOrderParams, commonApiParams, commonHeader);
      return res;
    } catch (err) {
      return err;
    }
  };
  const safeGetRelationMemeberData = async (): Promise<AxiosResponse<DataResponse<OrderPersonCheckResult>>> => {
    try {
      return await queryOrderHmaAcceptstatisticPersonCheck(queryOrderParams, commonApiParams, commonHeader);
    } catch (err) {
      return err;
    }
  };
  requests.push(safeGetOrderData());
  requests.push(safeGetRelationMemeberData());
  const [giftAbTestDataResponse, productDetailResponse, userGroupResponse, ifHasOrderResponse, homeMemberResponse] = await Promise.all(requests);

  const ybCompanyId: keyof typeof ENTITY_AND_PRODUCTID_MAP = productDetailResponse?.data?.data?.baseInfo?.ybCompanyId;
  const productEntity = ENTITY_AND_PRODUCTID_MAP[ybCompanyId];
  const userEntity = userGroupResponse?.data?.data?.groupId;
  // 转委托是否重新登录
  const prodcutAgentIsNeedLogin = !userEntityAndProductEntityIsMatch(entitySwitch, userEntity, productEntity);
  // 加密用户是否重新登录
  const encryptUserIsNeedLogin = giftAbTestDataResponse?.encryptLoginAbtest?.version === 2;
  const isNeedLogin = prodcutAgentIsNeedLogin || encryptUserIsNeedLogin;
  let unloginGiftAbTestDataResponse;
  // 如果是重新登陆，则需要重新请求非登录态ab测
  if (isNeedLogin) {
    const unloginAbTestParams = [
      { configCode: 'jiYanLoginAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
      { configCode: 'giftLinkAbtest', extraLimit: { ...abTestCommonParams, previewCode: previewCode ? +previewCode : null } },
      { configCode: 'giftFormAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
      { configCode: 'giftAudioAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftFormTipsAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftFormHeaderImgAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftLoginAndLandingLongImageAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftPage1Abtest', extraLimit: abTestCommonParams },
      { configCode: 'giftPage2Abtest', extraLimit: abTestCommonParams },
      { configCode: 'giftLoginBackAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftDetailBackAbtest', extraLimit: abTestCommonParams },
      { configCode: 'idCardAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftAutoOrderAbtest', extraLimit: abTestCommonParams },
      { configCode: 'greetingVariantAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftProConfirmAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftHandAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftProtocolAbtest', extraLimit: abTestCommonParams },
      // { configCode: 'giftLandingProtocalCheckboxAbtest', extraLimit: abTestCommonParams },
      { configCode: 'formPermanentResidentIDCardAbtest', extraLimit: abTestCommonParams },
      { configCode: 'icpStyleAbtest', extraLimit: abTestCommonParams }, // 页面底部ICP样式ab测
      { configCode: 'guaranteeGenerationStyleAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftUnderFormSwiperAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
    ];
    abDataArr = unloginAbTestParams.map((item) => item.configCode);
    unloginGiftAbTestDataResponse = await getShuntAbtestAll<GiftUnloginConfig2AbtestTemplates>(ybPosition, unloginAbTestParams, commonAbTestParams, commonApiParams, commonHeader);
  }
  const giftAbTestData = unloginGiftAbTestDataResponse ? unloginGiftAbTestDataResponse?.data : giftAbTestDataResponse?.data;

  // ab测默认值赋值
  if (giftAbTestData && giftAbTestData.data) {
    for (const key of abDataArr) {
      const keyData = giftAbTestData.data[key];
      if (!keyData) {
        giftAbTestData.data[key] = { version: 1 };
      } else if (!keyData?.version) {
        keyData.version = 1;
      }
    }
    if (giftAbTestData.data.giftAudioAbtest) {
      giftAbTestData.data.giftAudioAbtest.version = 7; // 默认兜底版本7  产品要求
    }
    // giftProConfirmAbtest 下线2，4，5，6
    if (giftAbTestData.data.giftProConfirmAbtest) {
      if ([2, 4, 5, 6].includes(giftAbTestData.data?.giftProConfirmAbtest?.version)) {
        giftAbTestData.data.giftProConfirmAbtest.version = 3;
      }
    }
    /** idCardAbtest 下线 3、6 https://yuanbaoshuke.feishu.cn/docx/HXCnd4PWkoCDvOxfzEtcDcemn7c */
    if ([3, 6].includes(giftAbTestData.data?.idCardAbtest?.version)) giftAbTestData.data.idCardAbtest.version = 2;
    if (giftAbTestData.data?.giftFormAbtest && [3, 6].includes(giftAbTestData.data.giftFormAbtest?.version)) {
      giftAbTestData.data.giftFormAbtest.version = 1;
    }
  }

  if (prodcutAgentIsNeedLogin) {
    return {
      abtestData: giftAbTestData,
      productData: productDetailResponse?.data,
      userGroupData: userGroupResponse?.data,
    };
  }

  return {
    abtestData: giftAbTestData,
    productData: productDetailResponse?.data,
    userGroupData: userGroupResponse?.data,
    orderData: ifHasOrderResponse?.data,
    homeMemberData: homeMemberResponse?.data,
  };
};
