/*
 * @Author: shixiaoxia
 * @Date: 2024-05-18 10:22:10
 * @LastEditors: liulongjie liulongjie@yuanbaobaoxian.com
 * @LastEditTime: 2024-11-20 21:55:37
 * @Description: 赠险链路服务
 */
import { CommonHeader } from '../../../../../fetch/base';
import { mallQueryDetail } from '../../../../../fetch/mallGateway';
import { isRequestError } from '../../../../../utils/httperr';
import { getShuntAbtestAll } from '../../../../../fetch/shunt';
import { UnLoginGiftDetailParam } from '../index';
import { GiftConfig1AbtestTemplates } from '../../types';
import { GiftDetailUnLoginConfig1Result } from '../types';
import { CommonParams } from '../../../../../types';

export const giftDetailUnLoginConfig1Service = async (
  giftParam: UnLoginGiftDetailParam & CommonParams,
  commonHeader: CommonHeader,
): Promise<GiftDetailUnLoginConfig1Result> => {
  const {
    queryDetailParams,
    abtestParams,
    commonApiParams,
    commonAbTestParams,
  } = giftParam;
  const { isWifi, productAbb, ybPosition, landingPageVersion } = abtestParams || {};
  const { shuntId } = commonApiParams;
  const commonParams = {
    version: landingPageVersion,
    productAbb,
    giftProductAbb: productAbb,
  };
  // shunt请求，分流
  const abTestParams = [
    { configCode: 'newVisionAbtest', extraLimit: { ...commonParams, isWifi } },
    { configCode: 'idCardAbtest', extraLimit: commonParams },
    { configCode: 'giftAudioAbtest', extraLimit: commonParams },
    { configCode: 'jiYanLoginAbtest', extraLimit: { ...commonParams, isWifi } },
    { configCode: 'smsVerificationCodeAbTest', extraLimit: { ...commonParams } },
    { configCode: 'page1Abtest', extraLimit: commonParams }, // 赠险中的投保提示以及协议展示仅给保司校验用 目前只能接入版本4
    { configCode: 'page2Abtest', extraLimit: commonParams },
    { configCode: 'giftLoginBackAbtest', extraLimit: commonParams, isWifi },
    { configCode: 'giftDetailBackAbtest', extraLimit: commonParams },
    { configCode: 'giftProtocolAbtest', extraLimit: commonParams },
    { configCode: 'giftpayLoadingAbtest', extraLimit: commonParams },
    { configCode: 'greetingVariantAbtest', extraLimit: commonParams },
    { configCode: 'giftProConfirmAbtest', extraLimit: commonParams, shuntId },
  ];
  const giftAbTestDataResponse = await getShuntAbtestAll<GiftConfig1AbtestTemplates>(ybPosition, abTestParams, commonAbTestParams, commonApiParams, commonHeader);
  const productAbTest = giftAbTestDataResponse?.data?.data;
  const abtestData = giftAbTestDataResponse?.data;
  const { newVisionAbtest } = productAbTest || {};
  // 针对newVision处理详情页数据
  const { jumpUrl, landPageVersion } = newVisionAbtest || {};
  if (jumpUrl) {
    return {
      abtestData,
    };
  }
  /**
   * https://yuanbaoshuke.feishu.cn/docx/RySedVCwgoIXn3xhTMIc7Kkznnh
   * 下线3 兜底1
   */
  if (abtestData?.data?.smsVerificationCodeAbTest?.version === 3) {
    abtestData.data.smsVerificationCodeAbTest = { version: 1 };
  }
  // 最终落地页版本
  const finalLandVersion = (landPageVersion || landingPageVersion || 0) as number;
  const queryDetailParam = {
    ...queryDetailParams,
    landingPageVersion: finalLandVersion, // 批增版本未使用，先统一传0
  };
  // 请求落地页详情
  const productDetailResponse = await mallQueryDetail(
    queryDetailParam,
    commonApiParams,
    commonHeader,
  );
  const productDetailErr = isRequestError(productDetailResponse?.data);
  if (productDetailErr) {
    return {
      abtestData,
      productData: productDetailResponse?.data,
    };
  }
  return {
    abtestData,
    productData: productDetailResponse?.data,
  };
};
