export enum InteractionTopic {
  /**
   * 展示弹窗 包含 有无医保弹窗 续保协议弹窗 普通协议弹窗
   * ！注意：这个事件收集不到ybModal.open的打开与关闭，如果功能涉及到这个api，需要在调用处补充startInteraction和endCurInteraction
   */
  ShowModal = 'ShowModal',
  /** 勾选协议 */
  CheckProtocal = 'CheckProtocal',
  /** 用户页面滑动 */
  TouchMove = 'TouchMove',
  /** 操作返回 */
  ClickBack = 'ClickBack',
  /** 键盘聚焦 */
  FocusInput = 'FocusInput',
  /** 点击按钮 */
  ClickBtn = 'ClickBtn',
}

/** 交互结束回调类型 */
export type InteractionCBType = (interaction: InteractionTopic) => boolean;
